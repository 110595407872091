import React from 'react'

import Attachments from 'apps/EmailTemplates/components/Attachments'
import SidebarPanel from '../SidebarPanel'

export default function AttachPanel({ emailTemplate, active }) {
  return (
    <SidebarPanel active={active} noPadding>
      <Attachments
        attachments={emailTemplate.attachments || []}
        templateId={emailTemplate.id}
      />
    </SidebarPanel>
  )
}
