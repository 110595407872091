{
  "Skip to main content": "Skip to main content",
  "In Progress": "In Progress",
  "Submitted": "Submitted",
  "You haven't started or submitted an application yet": "You haven't started or submitted an application yet",
  "Name": "Name",
  "Application": "Application",
  "Registration": "Registration",
  "This event is now full.": "This event is now full.",
  "This event has now passed.": "This event has now passed.",
  "Registrations are closed.": "Registrations are closed.",
  "Register": "Register",
  "Thank you for filling in the form.": "Thank you for filling in the form.",
  "You will receive an email shortly.": "You will receive an email shortly.",
  "Email not verified": "Email not verified",
  "A verification email was sent to {{email}}.": "A verification email was sent to {{email}}.",
  "Please check your email.": "Please check your email.",
  "Email sent": "Email sent",
  "Email failed!": "Email failed!",
  "Resend verification email": "Resend verification email",
  "Change to English": "Change to English",
  "Locale": "English",
  "Login": "Login",
  "Home": "Home",
  "My Applications": "My Applications",
  "My Registrations": "My Registrations",
  "Other Content": "Other Content",
  "Menu": "Menu",
  "Close navigation": "Close navigation",
  "Main Menu": "Main Menu",
  "Close": "Close",
  "Applications": "Applications",
  "No applications available": "No applications available",
  "To access registrations please register or login.": "To access registrations please register or login.",
  "To access applications please register or login.": "To access applications please register or login.",
  "You haven't started or submitted a registration yet": "You haven't started or submitted a registration yet",
  "Registrations": "Registrations",
  "No registrations available": "No registrations available",
  "Start registration": "Start registration",
  "Start application": "Start application",
  "Start another registration": "Start another registration",
  "Start another application": "Start another application",
  "Continue registration": "Continue registration",
  "Continue application": "Continue application",
  "today at {{ time }}": "today at {{ time }}",
  "tomorrow at {{ time }}": "tomorrow at {{ time }}",
  "on {{ date }} at {{ time }}": "on {{ date }} at {{ time }}",
  "on {{ date }}": "on {{ date }}",
  "Closes {{ dateAndTimeText }}": "Closes {{ dateAndTimeText }}",
  "Opens {{ dateAndTimeText }}": "Opens {{ dateAndTimeText }}",
  "Registrations are closed": "Registrations are closed",
  "Applications are closed": "Applications are closed",
  "Loading": "Loading",
  "Choose a programme or subject": "Choose a programme or subject",
  "This field is required": "This field is required",
  "Choose a class or course": "Choose a class or course",
  "Choose a campus": "Choose a campus",
  "Registration options": "Registration options",
  "Application options": "Application options",
  "Cancel": "Cancel",
  "Continue": "Continue",
  "Please select an option": "Please select an option",
  "You can change your selection later.": "You can change your selection later.",
  "View registration": "View registration",
  "View application": "View application",
  "Accept": "Accept",
  "Decline": "Decline",
  "View Submission": "View Submission",
  "Conditional offer": "Conditional offer",
  "Unconditional offer": "Unconditional offer",
  "No events": "No events",
  "<0>{{dateFullDay}}</0> at <2>{{dateTime}}</2> (<4>{{timezone}}</4> time)": "<0>{{dateFullDay}}</0> at <2>{{dateTime}}</2> (<4>{{timezone}}</4> time)",
  "Learn more": "Learn more",
  "Upcoming Events": "Upcoming Events",
  "Hide map": "Hide map",
  "Show map": "Show map",
  "<0>{{start}}</0> - <3>{{end}}</3> (<5>{{timezone}}</5> time)": "<0>{{start}}</0> - <3>{{end}}</3> (<5>{{timezone}}</5> time)",
  "Please fill out the form": "Please fill out the form",
  "Data Protection Regulation": "Data Protection Regulation",
  "Public profile": "Public profile",
  "Transcripts": "Transcripts",
  "Calendar": "Calendar",
  "Settings": "Settings",
  "Logout": "Logout",
  "Someone already registered with that email address": "Someone already registered with that email address",
  "Email address is invalid": "Email address is invalid",
  "Email already in use": "Email already in use",
  "Participant details": "Participant details",
  "Please enter the details of the participant": "Please enter the details of the participant",
  "Email address": "Email address",
  "First name": "First name",
  "Last name": "Last name",
  "Offers": "Offers",
  "{{ name }} (closed)": "{{ name }} (closed)",
  "Payments": "Payments",
  "View your fees and make payments": "View your fees and make payments",
  "Paid <1></1>": "Paid <1></1>",
  "Due in {{timeToDueDate}}": "Due in {{timeToDueDate}}",
  "Due today": "Ska betalas idag",
  "{{timeFromDueDate}} overdue": "{{timeFromDueDate}} overdue",
  "Due Date": "Due Date",
  "Status": "Status",
  "Description": "Description",
  "Amount": "Amount",
  "Payment plan for": "Payment plan for",
  "Alerts": "Alerts",
  "Next payment:": "Next payment:",
  "See details": "See details",
  "Discount": "Discount",
  "Total": "Total",
  "Paid": "Paid",
  "Awaiting confirmation": "Väntar på bekräftelse",
  "Due soon": "Snart förfallen",
  "Due / Overdue": "Förfallen / Försenad",
  "Back to list": "Back to list",
  "Fee details": "Fee details",
  "Payment Plans": "Payment Plans",
  "Pay now": "Pay now",
  "Pay with Flywire": "Pay with Flywire",
  "Flywire billing details": "Flywire billing details",
  "Email": "Email",
  "Phone number": "Phone number",
  "Address line 1": "Address line 1",
  "Address line 2": "Address line 2",
  "Country": "Country",
  "City": "City",
  "Zip code": "Zip code",
  "State": "State",
  "Awaiting payment confirmation": "Awaiting payment confirmation",
  "We are awaiting confirmation of your payment.": "We are awaiting confirmation of your payment.",
  "You will be sent an email when your payment has been received successfully. Please select another method if you had problems making your payment.": "You will be sent an email when your payment has been received successfully. Please select another method if you had problems making your payment.",
  "There are fields with errors.": "There are fields with errors.",
  "Please correct the fields with errors.": "Please correct the fields with errors.",
  "Missing {{amount}}": "Missing {{amount}}",
  "Payment": "Payment",
  "There was an error in your submission, please try again in a few seconds. If the issue persists, please contact us.": "There was an error in your submission, please try again in a few seconds. If the issue persists, please contact us.",
  "{{ name }} (not currently available)": "{{ name }} (not currently available)",
  "{{ name }} (not available yet)": "{{ name }} (not available yet)",
  "They will open on <2>{{date}}</2>.": "They will open on <2>{{date}}</2>.",
  "Registrations are currently closed.": "Registrations are currently closed.",
  "Registrations will open on {{ registrationsOpenDate }}.": "Registrations will open on {{registrationsOpenDate}}.",
  "Select an option": "Välj ett alternativ",
  "({{timezone}} time)": "({{timezone}} time)",
  "{{dateFullDay}} at {{dateTime}}": "{{dateFullDay}} at {{dateTime}}",
  "Product catalog": "Produktkatalog",
  "Search for a programme or course": "Sök efter ett program eller en kurs",
  "Start date": "Start datum",
  "End date": "Slutdatum",
  "Duration": "Varaktighet",
  "Location": "Plats",
  "Price": "Pris",
  "Application deadline": "Ansökningstiden",
  "Registration deadline": "Senast registrering",
  "Apply": "Tillämpa",
  "Details": "Details",
  "All": "All",
  "Now until the end of next month": "Now until the end of next month",
  "In 1-6 months": "In 1-6 months",
  "In 6-12 months": "In 6-12 months",
  "In 1 year": "In 1 year",
  "Theme": "Theme",
  "Pay with Stripe": "Betala med Stripe",
  "Stripe payment details": "Stripe betalningsinformation",
  "Your payment was processed": "Din betalning behandlades",
  "Edit application": "Redigera ansökan",
  "Edit registration": "Redigera registrering",
  "Have a discount code?": "Har du en rabattkod?",
  "In order to submit you are required to pay a fee.": "För att skicka in måste du betala en avgift.",
  "All fees paid": "Alla avgifter betalda",
  "Next step:": "Nästa steg:",
  "Sign & Submit": "Underteckna och skicka",
  "Submit": "Skicka in",
  "I accept the privacy policy": "I accept the privacy policy",
  "Privacy Policy": "Privacy Policy",
  "All fees paid.": "Alla avgifter betalda.",
  "Next step.": "Nästa steg.",
  "Finish your application by clicking the button below.": "Slutför din ansökan genom att klicka på knappen nedan.",
  "Finish your registration by clicking the button below.": "Slutför din registrering genom att klicka på knappen nedan.",
  "Check your inbox": "Kontrollera din inkorg",
  "A new link has been sent.": "En ny länk har skickats.",
  "Access expired": "Åtkomst utgången",
  "Click the button below to receive a new link in your inbox.": "Klicka på knappen nedan för att få en ny länk i din inkorg.",
  "Send new link": "Skicka ny länk",
  "Payment link is invalid": "Betalningslänken är ogiltig",
  "This payment has been canceled or does not exist.": "Denna betalning har avbrutits eller finns inte.",
  "Fee has been paid": "Avgiften har betalats",
  "The requester has been notified.": "Begäran har meddelats.",
  "On {{date}}, this fee was sent to an external payer.": "On {{date}}, this fee was sent to an external payer.",
  "Name is invalid": "Name is invalid",
  "Manage payer": "Manage payer",
  "Send to payer": "Send to payer",
  "Cancel external payer": "Cancel external payer",
  "Resend fee details": "Resend fee details",
  "Send fee": "Send fee",
  "An email containing all the payment details will be sent to the external payer.": "An email containing all the payment details will be sent to the external payer.",
  "Payer's email address": "Payer's email address",
  "Payer's name": "Payer's name",
  "Actions": "Actions",
  "External payer access was revoked": "External payer access was revoked",
  "Fee was sent to an external payer": "Fee was sent to an external payer",
  "Fee paid": "Fee paid"
}
