import { utils } from '@fullfabric/public-api'

export async function uploadFile(file) {
  const formData = new FormData()
  formData.append('file', file)

  const response = await fetch(`/api/files`, {
    method: 'POST',
    credentials: 'same-origin',
    body: formData
  })

  return await utils.checkResponse(response)
}
