export const TextIcon = `<svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.869 0H1.88504C0.844803 0.00283435 0.00236033 0.845661 0 1.8859V3.50889C8.83848e-09 4.10203 0.480835 4.58287 1.07398 4.58287C1.66712 4.58287 2.14795 4.10203 2.14795 3.50889V2.36275C2.14795 2.24412 2.24412 2.14795 2.36275 2.14795H7.08824C7.20687 2.14795 7.30303 2.24412 7.30303 2.36275V17.6373C7.30303 17.7559 7.20687 17.852 7.08824 17.852H5.53656C4.94342 17.852 4.46258 18.3329 4.46258 18.926C4.46258 19.5192 4.94342 20 5.53656 20H11.2175C11.8106 20 12.2914 19.5192 12.2914 18.926C12.2914 18.3329 11.8106 17.852 11.2175 17.852H9.66578C9.54715 17.852 9.45098 17.7559 9.45098 17.6373V2.36275C9.45098 2.24412 9.54715 2.14795 9.66578 2.14795H14.3913C14.5099 2.14795 14.6061 2.24412 14.6061 2.36275V3.50889C14.6061 4.10203 15.0869 4.58287 15.68 4.58287C16.2732 4.58287 16.754 4.10203 16.754 3.50889V1.8859C16.7517 0.845661 15.9092 0.00283435 14.869 0Z" fill="#18191F"/>
</svg>
`
export const ButtonIcon = `<svg width="24" height="11" viewBox="0 0 24 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M24 2C24 0.895431 23.1046 0 22 0H2C0.895431 0 0 0.895431 0 2V9C0 10.1046 0.89543 11 2 11H22C23.1046 11 24 10.1046 24 9V2ZM5.5 5C5.22386 5 5 5.22386 5 5.5C5 5.77614 5.22386 6 5.5 6H18.5C18.7761 6 19 5.77614 19 5.5C19 5.22386 18.7761 5 18.5 5H5.5Z" fill="#18191F"/>
</svg>
`
export const ImageIcon = `<svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.89543 20 2 20H22C23.1046 20 24 19.1046 24 18V2C24 0.895431 23.1046 0 22 0H2ZM3 2C2.44772 2 2 2.44772 2 3V17C2 17.5523 2.44772 18 3 18H21C21.5523 18 22 17.5523 22 17V3C22 2.44772 21.5523 2 21 2H3Z" fill="#18191F"/>
<path d="M7.5 10C8.88071 10 10 8.88071 10 7.5C10 6.11929 8.88071 5 7.5 5C6.11929 5 5 6.11929 5 7.5C5 8.88071 6.11929 10 7.5 10Z" fill="#18191F"/>
<path d="M14.4418 10.2799C14.3258 10.1058 14.1223 10 13.9034 10C13.6845 10 13.481 10.1058 13.365 10.2799L10.2845 14.9031C10.2261 14.9896 10.1245 15.042 10.0153 15.042C9.90617 15.042 9.80459 14.9896 9.74614 14.9031L8.64906 13.2572C8.53304 13.0831 8.32957 12.9774 8.11067 12.9774C7.89177 12.9774 7.6883 13.0831 7.57228 13.2572L4.04865 18.5439C3.98715 18.6357 3.98372 18.7516 4.03971 18.8465C4.09569 18.9413 4.20224 19.0001 4.31784 19H19.6822C19.7978 19.0001 19.9043 18.9413 19.9603 18.8465C20.0163 18.7516 20.0129 18.6357 19.9514 18.5439L14.4418 10.2799Z" fill="#18191F"/>
</svg>
`

export const VideoIcon = `<svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2 10C2 4.47715 6.47715 0 12 0C17.5204 0.00597071 21.994 4.47963 22 10C22 15.5228 17.5228 20 12 20C6.47715 20 2 15.5228 2 10ZM4.60943 13.0622C5.84793 16.0515 8.76524 18.0004 12.001 18V17.9992C16.4166 17.994 19.9949 14.4158 20 10.0001C20 6.76441 18.0508 3.84731 15.0613 2.60913C12.0719 1.37095 8.63093 2.05554 6.34304 4.34367C4.05516 6.63179 3.37093 10.0728 4.60943 13.0622Z" fill="#18191F"/>
<path d="M15.4221 9.13327L11.4807 7.13238C11.166 6.94982 10.7782 6.95651 10.4698 7.14981C10.1614 7.34312 9.98174 7.69212 10.0015 8.05955V11.9452C9.98716 12.3109 10.1642 12.657 10.4671 12.8555C10.6188 12.9504 10.7936 13.0005 10.9719 13C11.148 12.9981 11.3213 12.9546 11.478 12.873L15.4195 10.8721C15.77 10.7303 16 10.3858 16 10.0027C16 9.61957 15.77 9.27513 15.4195 9.13327H15.4221Z" fill="#18191F"/>
</svg>
`

export const SocialIcon = `<svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.8357 11.6674C16.7082 11.6683 15.6298 12.1286 14.849 12.9423L10.274 10.6534C10.3133 10.438 10.3353 10.2198 10.3398 10.0009C10.3353 9.78203 10.3133 9.56385 10.274 9.34847L14.849 7.05951C16.2363 8.48588 18.4393 8.72896 20.104 7.63935C21.7686 6.54974 22.4284 4.4329 21.6777 2.58982C20.927 0.746746 18.9763 -0.305885 17.0245 0.0788671C15.0728 0.463619 13.6671 2.17789 13.6713 4.16811C13.6759 4.38727 13.6979 4.60572 13.7371 4.82139L9.16216 7.10868C7.98348 5.88565 6.18059 5.50116 4.60585 6.13698C3.03111 6.7728 2 8.30156 2 10.0005C2 11.6994 3.03111 13.2282 4.60585 13.864C6.18059 14.4998 7.98348 14.1153 9.16216 12.8923L13.7371 15.1804C13.6978 15.3961 13.6758 15.6145 13.6713 15.8337C13.6713 18.1347 15.5358 20 17.8357 20C20.1356 20 22 18.1347 22 15.8337C22 13.5327 20.1356 11.6674 17.8357 11.6674Z" fill="#18191F"/>
</svg>
`

export const MenuIcon = `<svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="9" width="24" height="2" rx="1" fill="#18191F"/>
<rect y="14" width="24" height="2" rx="1" fill="#18191F"/>
<rect y="4" width="24" height="2" rx="1" fill="#18191F"/>
</svg>
`

export const DividerIcon = `<svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4" y="2" width="16" height="4" rx="1" fill="#B9BFC7"/>
<rect x="4" y="14" width="16" height="4" rx="1" fill="#B9BFC7"/>
<rect y="9" width="24" height="2" rx="1" fill="#18191F"/>
</svg>
`

export const SpacerIcon = `<svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4" width="16" height="4" rx="1" fill="#B9BFC7"/>
<rect x="4" y="16" width="16" height="4" rx="1" fill="#B9BFC7"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M2 6C0.895431 6 0 6.89543 0 8V12C0 13.1046 0.89543 14 2 14H22C23.1046 14 24 13.1046 24 12V8C24 6.89543 23.1046 6 22 6H2ZM3 8C2.44772 8 2 8.44772 2 9V11C2 11.5523 2.44772 12 3 12H21C21.5523 12 22 11.5523 22 11V9C22 8.44772 21.5523 8 21 8H3Z" fill="#18191F"/>
</svg>
`

export const HtmlIcon = `<svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.2193 10.2193C10.6097 9.82884 10.6097 9.19584 10.2193 8.80534L7.71931 6.30534C7.46824 6.04539 7.09644 5.94113 6.74681 6.03265C6.39718 6.12416 6.12413 6.39721 6.03262 6.74684C5.9411 7.09647 6.04536 7.46827 6.30531 7.71934L7.92131 9.33534C7.96833 9.38225 7.99476 9.44593 7.99476 9.51234C7.99476 9.57876 7.96833 9.64244 7.92131 9.68934L6.30531 11.3053C6.04536 11.5564 5.9411 11.9282 6.03262 12.2779C6.12413 12.6275 6.39718 12.9005 6.74681 12.992C7.09644 13.0836 7.46824 12.9793 7.71931 12.7193L10.2193 10.2193ZM16.0123 12.5123C16.5646 12.5123 17.0123 12.0646 17.0123 11.5123C17.0123 10.9601 16.5646 10.5123 16.0123 10.5123H12.0123C11.46 10.5123 11.0123 10.9601 11.0123 11.5123C11.0123 12.0646 11.46 12.5123 12.0123 12.5123H16.0123Z" fill="#18191F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.89543 20 2 20H22C23.1046 20 24 19.1046 24 18V2C24 0.895431 23.1046 0 22 0H2ZM3 2C2.44772 2 2 2.44772 2 3V17C2 17.5523 2.44772 18 3 18H21C21.5523 18 22 17.5523 22 17V3C22 2.44772 21.5523 2 21 2H3Z" fill="#18191F"/>
</svg>`

export const OneColumnIcon = `<svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895431 0 0 0.895431 0 2V18C0 19.1046 0.89543 20 2 20H22C23.1046 20 24 19.1046 24 18V2C24 0.895431 23.1046 0 22 0H2ZM3 2C2.44772 2 2 2.44772 2 3V17C2 17.5523 2.44772 18 3 18H21C21.5523 18 22 17.5523 22 17V3C22 2.44772 21.5523 2 21 2H3Z" fill="#18191F"/>
</svg>
`

export const TwoColumnsIcon = `<svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 2C0 0.895431 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V18C24 19.1046 23.1046 20 22 20H2C0.89543 20 0 19.1046 0 18V2ZM2 3C2 2.44772 2.44772 2 3 2H10C10.5523 2 11 2.44772 11 3V17C11 17.5523 10.5523 18 10 18H3C2.44772 18 2 17.5523 2 17V3ZM14 2C13.4477 2 13 2.44772 13 3V17C13 17.5523 13.4477 18 14 18H21C21.5523 18 22 17.5523 22 17V3C22 2.44772 21.5523 2 21 2H14Z" fill="#18191F"/>
</svg>
`

export const ThreeColumnsIcon = `<svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2 0H22C23.1046 0 24 0.746192 24 1.66667V18.3333C24 19.2538 23.1046 20 22 20H2C0.89543 20 0 19.2538 0 18.3333V1.66667C0 0.746192 0.89543 0 2 0ZM15 17.9167V2.08333C15 1.85321 14.7761 1.66667 14.5 1.66667H9.5C9.22386 1.66667 9 1.85321 9 2.08333V17.9167C9 18.1468 9.22386 18.3333 9.5 18.3333H14.5C14.7761 18.3333 15 18.1468 15 17.9167ZM2.5 1.66667H6.5C6.77614 1.66667 7 1.85321 7 2.08333V17.9167C7 18.1468 6.77614 18.3333 6.5 18.3333H2.5C2.22386 18.3333 2 18.1468 2 17.9167V2.08333C2 1.85321 2.22386 1.66667 2.5 1.66667ZM17.5 18.3333H21.5C21.7761 18.3333 22 18.1468 22 17.9167V2.08333C22 1.85321 21.7761 1.66667 21.5 1.66667H17.5C17.2239 1.66667 17 1.85321 17 2.08333V17.9167C17 18.1468 17.2239 18.3333 17.5 18.3333Z" fill="black"/>
</svg>
`
