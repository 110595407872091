import React, { useCallback, useEffect, useRef, useState } from 'react'

import grapesjs from 'grapesjs'
import presetMjml from 'grapesjs-mjml'
import { compile } from 'html-to-text'

import GjsEditor from '@grapesjs/react'

import useSettingByName from 'apps/ContentPages/hooks/use-setting-by-name'

import './grapejs.scss'

import { debounce } from 'lodash'

import { Loading } from '@fullfabric/alma-mater'

import { baseTemplateGrapejs } from 'apps/EmailTemplates/constants/baseTemplateGrapejs'
import { useEmailTemplateImages } from 'apps/EmailTemplates/hooks/useEmailTemplateImages'
import { useMergeTags } from 'apps/EmailTemplates/hooks/useMergeTags'
import { getGrapeJsOptionsConfig } from './config'
import { mergeTagPlugin } from './plugins/mergeTagPlugin'
import { Sidebar } from './Sidebar'

import styles from './styles.module.scss'

const compiledConvert = compile({})

export default function GrapejsEditor({ emailTemplate, onSave, onChange }) {
  const mergeTags = useMergeTags()
  // Just a trigger to re-render the component when the editor is ready
  const [, setHasEditor] = useState(false)
  const editorRef = useRef(null)
  const colors = useSettingByName(
    'modules.core.submodules.email_templates.themes.colors'
  )
  const { data: dataImages, isLoading } = useEmailTemplateImages(
    emailTemplate.id
  )

  const imageUploadResponse = useCallback((responseData) => {
    editorRef.current.AssetManager.add({
      src: responseData.url,
      type: 'image'
    })
  }, [])

  const onEditorSave = debounce((editor) => {
    const html = editor.runCommand('mjml-code-to-html')?.html
    const mjml = editor.runCommand('mjml-code')

    onSave({ html, mjml, text: compiledConvert(html) })
  }, 500)

  const onEditor = (grapeEditor) => {
    editorRef.current = grapeEditor
    grapeEditor.addComponents(emailTemplate.mjml || baseTemplateGrapejs)
    grapeEditor.Panels.removeButton('options', 'export-template')
    grapeEditor.Panels.removeButton('options', 'mjml-import')
    grapeEditor.on('asset:upload:response', imageUploadResponse)
    setHasEditor(grapeEditor)
  }

  const onGrapeJsUpdate = (_, editor) => {
    onChange()
    onEditorSave(editor)
  }

  useEffect(() => {
    return () => {
      editorRef.current?.off('asset:upload:response', imageUploadResponse)
    }
  }, [imageUploadResponse])

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className={styles.container}>
      <Sidebar editor={editorRef.current} emailTemplate={emailTemplate} />
      <GjsEditor
        aria-label='Grapejs email editor'
        role='main'
        style={{ flexGrow: '1', width: 'initial' }}
        grapesjs={grapesjs}
        options={getGrapeJsOptionsConfig(
          colors,
          emailTemplate,
          mergeTags,
          dataImages
        )}
        onUpdate={onGrapeJsUpdate}
        plugins={[presetMjml, mergeTagPlugin]}
        onEditor={onEditor}
      />
    </div>
  )
}
