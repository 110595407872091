.react-app ._badgesAligner_ctp14_1 {
  display: inline-flex;
  flex-wrap: wrap;
}

.react-app ._badgeContainer_ctp14_6 {
  display: inline-flex;
  flex-wrap: nowrap;
}

.react-app ._badgeSpacing_ctp14_11 {
  margin-right: var(--space-tiny);
}