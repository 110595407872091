import React from 'react'
import { useController, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import {
  Button,
  Field,
  FieldWrapper,
  FileUploadInput,
  Modal,
  ModalButtons,
  ModalTitle
} from '@fullfabric/alma-mater'

import { uploadFile } from 'shared/api/uploadFile'

export const FileUploadModal = ({ isOpen, onClose, editor }) => {
  const { t } = useTranslation()
  const formRef = React.useRef()
  const { control, handleSubmit, setValue } = useForm()
  const {
    field: { ref: nameRef, ...nameProps }
  } = useController({ name: 'name', control })

  const { mutate: uploadMutation, isLoading: isUploading } =
    useMutation(uploadFile)

  const onSubmit = (data) => {
    uploadMutation(data.file, {
      onSuccess: (response) => {
        editor.commands.insertContent(
          `<a href="${response.url}">${data.name || response.filename}</a>`
        )
        onClose()
      }
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      keyboard={false}
      header={<ModalTitle title={t('Upload a file')} onClose={onClose} />}
      footer={
        <ModalButtons>
          <Button
            primary
            loading={isUploading}
            onClick={() => formRef.current.requestSubmit()}
          >
            {t('Upload & Insert')}
          </Button>
        </ModalButtons>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <Field
          type='text'
          id='name'
          label={t('Text')}
          inputRef={nameRef}
          required
          {...nameProps}
        />
        <FieldWrapper required id='upload' label={t('File')}>
          <FileUploadInput
            id='upload'
            multiple={false}
            value={[]}
            onChange={(state) => {
              if (state.selectedFiles.length === 1) {
                setValue('file', state.selectedFiles[0])
              }
            }}
          />
        </FieldWrapper>
      </form>
    </Modal>
  )
}
