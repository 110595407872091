/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState } from 'react'

import Tippy from '@tippyjs/react'

import Link from './link'

import styles from './styles.module.scss'

const LinkPopUpFolder = ({ placement = 'right-start', links, children }) => {
  const [instance, setInstance] = useState()
  return (
    <div>
      {/* wrapper div as fix for tab navigation as recommended by tippy */}
      <Tippy
        duration={100}
        interactive
        trigger='click'
        hideOnClick
        placement={placement}
        onCreate={setInstance}
        content={
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div className={styles.folder} onClick={instance?.hide}>
            {links.map((link) => (
              <Link
                key={link.path}
                external={link.external}
                to={link.path}
                label={link.navigation_label}
                onClick={link.on_click}
              />
            ))}
          </div>
        }
        popperOptions={{
          modifiers: [
            { name: 'preventOverflow', enabled: false },
            { name: 'hide', enabled: false }
          ]
        }}
      >
        <div>{children}</div>
      </Tippy>
    </div>
  )
}

export default LinkPopUpFolder
