.react-app ._name_38ul9_1 {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  overflow: hidden;
  max-width: 100%;
}

.react-app ._row_38ul9_9 {
  display: flex;
  width: 100%;
  gap: var(--space-tiny);
  align-items: center;
  height: 38px;
}

.react-app ._actions_38ul9_17 {
  display: flex;
  gap: 8px;
}

.react-app ._rowWithError_38ul9_22 {
  flex-direction: column;
}