import { mergeTagPlugin } from './plugins/mergeTagPlugin'

const swv = 'sw-visibility'
const ful = 'fullscreen'
const prv = 'preview'

export const getGrapeJsOptionsConfig = (
  colors,
  emailTemplate,
  mergeTags,
  images
) => ({
  height: 'calc(100vh - 73px)',
  storageManager: false,
  colorPicker: {
    palette: [colors.length > 0 ? colors.map((color) => `#${color}`) : []]
  },
  assetManager: {
    upload: `/api/email_templates/${emailTemplate.id}/uploads`,
    uploadName: 'file',
    showUrlInput: false,
    multiUpload: false,
    autoAdd: false,
    assets: images?.map((image) => ({
      src: image.url,
      type: 'image'
    }))
  },
  canvas: {
    frameStyle: `
      body { background-color: #fff }
      * ::-webkit-scrollbar-track { background: rgba(0, 0, 0, 0.1) }
      * ::-webkit-scrollbar-thumb { background: rgba(255, 255, 255, 0.2) }
      * ::-webkit-scrollbar { width: 10px }
      *[contenteditable=true] { outline: none; }
    `
  },
  styleManager: {
    appendTo: '#styles-container'
  },
  traitManager: {
    appendTo: '#settings-container'
  },
  panels: {
    stylePrefix: 'pn-',
    defaults: [
      {
        id: 'commands',
        buttons: [{}]
      },
      {
        id: 'options',
        buttons: [
          {
            active: true,
            id: swv,
            className: 'fa fa-square-o',
            command: 'core:component-outline',
            context: swv,
            attributes: { title: 'View components' }
          },
          {
            id: prv,
            className: 'fa fa-eye',
            command: prv,
            context: prv,
            attributes: { title: 'Preview' }
          },
          {
            id: ful,
            className: 'fa fa-arrows-alt',
            command: ful,
            context: ful,
            attributes: { title: 'Fullscreen' }
          }
        ]
      }
    ]
  },
  pluginsOpts: {
    [mergeTagPlugin]: {
      mergeTags
    }
  }
})
