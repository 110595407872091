import React from 'react'

import { Dropdown } from '@fullfabric/alma-mater'

export default function DropDownMergeTags({
  name,
  value,
  mergeTags,
  onAddTag
}) {
  if (mergeTags) {
    const children = Object.values(mergeTags).map((data) => {
      return <DropDownMergeTags key={data.name} {...data} onAddTag={onAddTag} />
    })

    return name ? (
      <Dropdown.Menu.OptionGroup title={name} key={name}>
        {children}
      </Dropdown.Menu.OptionGroup>
    ) : (
      children
    )
  }

  return (
    <Dropdown.Menu.Option onMouseDown={(ev) => onAddTag(ev, { name, value })}>
      {name}
    </Dropdown.Menu.Option>
  )
}
