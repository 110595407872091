import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@fullfabric/alma-mater'
import { IfPermit } from '@fullfabric/authorization-officer'

import styles from './styles.module.scss'

export default function ExportButton({ contractId }) {
  const { t } = useTranslation()

  return (
    <IfPermit to='billing.billing_access'>
      <Button
        className={styles.exportButton}
        primary
        onClick={() =>
          window.open(`/billing/api/contracts/${contractId}/events.xlsx`)
        }
      >
        {t('Export list')}
      </Button>
    </IfPermit>
  )
}
