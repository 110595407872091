{
  "Skip to main content": "Zum Hauptinhalt springen",
  "In Progress": "In Arbeit",
  "Submitted": "Eingereicht",
  "You haven't started or submitted an application yet": "Sie haben noch keine Bewerbung begonnen oder eingereicht",
  "Name": "Name",
  "Application": "Bewerbung",
  "Registration": "Anmeldung",
  "This event is now full.": "Diese Veranstaltung ist jetzt voll.",
  "This event has now passed.": "Dieses Ereignis ist nun vorbei.",
  "Registrations are closed.": "Anmeldungen sind geschlossen.",
  "Register": "Registrieren",
  "Thank you for filling in the form.": "Danke, dass Sie das Formular ausgefüllt haben.",
  "You will receive an email shortly.": "Sie erhalten in Kürze eine E-Mail.",
  "Email not verified": "E-Mail nicht verifiziert",
  "A verification email was sent to {{email}}.": "Eine Bestätigungs-E-Mail wurde an {{email}} gesendet.",
  "Please check your email.": "Bitte überprüfen Sie Ihre E-Mail.",
  "Email sent": "E-Mail gesendet",
  "Email failed!": "E-Mail fehlgeschlagen!",
  "Resend verification email": "Bestätigungsmail erneut senden",
  "Change to English": "Wechsel Auf Deutsch",
  "Locale": "Deutsch",
  "Login": "Login",
  "Home": "Home",
  "My Applications": "Meine Bewerbungen",
  "My Registrations": "Meine Anmeldungen",
  "Other Content": "Andere Inhalte",
  "Menu": "Menu",
  "Close navigation": "Navigation schließen",
  "Main Menu": "Hauptmenü",
  "Close": "Schließen",
  "Applications": "Bewerbungen",
  "No applications available": "Keine Bewerbung verfügbar",
  "To access registrations please register or login.": "Um Zugang zu den Anmeldungen zu erhalten, registrieren Sie sich bitte oder loggen Sie sich ein.",
  "To access applications please register or login.": "Um auf den Bewerbungsbereich zugreifen zu können, registrieren Sie sich bitte zuerst oder melden sich an.",
  "You haven't started or submitted a registration yet": "Sie haben noch keine Anmeldung begonnen oder eingereicht",
  "Registrations": "Anmeldungen",
  "No registrations available": "Keine Anmeldungen verfügbar",
  "Start registration": "Anmeldung starten",
  "Start application": "Bewerbung starten",
  "Start another registration": "Weitere Anmeldung starten",
  "Start another application": "Weitere Bewerbung starten",
  "Continue registration": "Anmeldung fortsetzen",
  "Continue application": "Bewerbung fortsetzen",
  "today at {{ time }}": "heute um {{time}}",
  "tomorrow at {{ time }}": "morgen um {{time}}",
  "on {{ date }} at {{ time }}": "zum {{date}} unter {{time}}",
  "on {{ date }}": "zum {{date}}",
  "Registrations are closed": "Anmeldungen sind geschlossen",
  "Applications are closed": "Bewerbungen sind geschlossen",
  "Loading": "Laden von",
  "Choose a programme or subject": "Wählen Sie ein Programm oder Thema",
  "This field is required": "Dieses Feld ist erforderlich",
  "Choose a class or course": "Wählen Sie eine Klasse oder einen Kurs",
  "Choose a campus": "Wählen Sie einen Campus",
  "Registration options": "Optionen zur Anmeldung",
  "Application options": "Anwendungsoptionen",
  "Cancel": "Abbrechen",
  "Continue": "Fortfahren",
  "Please select an option": "Bitte wählen Sie eine Option aus",
  "You can change your selection later.": "Sie können Ihre Auswahl später ändern.",
  "View registration": "Anmeldung anzeigen",
  "View application": "Bewerbung ansehen",
  "Accept": "Akzeptieren",
  "Decline": "Ablehnen",
  "View Submission": "Einreichung anzeigen",
  "Conditional offer": "Bedingtes Angebot",
  "Unconditional offer": "Bedingungsloses Angebot",
  "No events": "Keine Ereignisse",
  "Learn more": "Erfahren Sie mehr",
  "Upcoming Events": "Kommende Veranstaltungen",
  "Calendar": "Kalender",
  "Closes {{ dateAndTimeText }}": "Schließt {{dateAndTimeText}}",
  "Data Protection Regulation": "Datenschutzverordnung",
  "Email address": "E-Mail-Adresse",
  "Email address is invalid": "E-Mail-Adresse ist ungültig",
  "Email already in use": "Diese Email-Adresse wird bereits verwendet",
  "First name": "Vorname",
  "Hide map": "Karte ausblenden",
  "Last name": "Nachname",
  "Logout": "Abmelden",
  "Offers": "Angebote",
  "Opens {{ dateAndTimeText }}": "Eröffnet {{dateAndTimeText}}",
  "Participant details": "Teilnehmerdaten",
  "Please enter the details of the participant": "Bitte geben Sie die Daten des Teilnehmers ein",
  "Please fill out the form": "Bitte füllen Sie das Formular aus",
  "Public profile": "Öffentliches Profil",
  "Settings": "Einstellungen",
  "Show map": "Karte anzeigen",
  "Someone already registered with that email address": "Jemand, der bereits mit dieser E-Mail-Adresse registriert ist",
  "Transcripts": "Abschriften",
  "{{ name }} (closed)": "{{name}} (geschlossen)",
  "Payments": "Zahlungen",
  "View your fees and make payments": "Sehen Sie Ihre Gebühren und Zahlungen ausführen",
  "Paid <1></1>": "Bezahlt <1></1>",
  "Due in {{timeToDueDate}}": "Fällig {{timeToDueDate}}",
  "Due today": "Heute fällig",
  "{{timeFromDueDate}} overdue": "{{timeFromDueDate}} überfällig",
  "Due Date": "Geburtstermin",
  "Status": "Staat",
  "Description": "Beschreibung",
  "Amount": "Menge",
  "Payment plan for": "Zahlungsplan für",
  "Alerts": "Warnungen",
  "Next payment:": "Nächste Zahlung:",
  "See details": "Siehe Details",
  "Discount": "Rabatt",
  "Total": "Gesamt",
  "Paid": "Bezahlt",
  "Awaiting confirmation": "Warten auf Bestätigung",
  "Due soon": "Bald fällig",
  "Due / Overdue": "Fällig / Überfällig",
  "Back to list": "Zurück zur Liste ",
  "Fee details": "Gebührendetails",
  "Payment Plans": "Zahlungspläne",
  "Pay now": "Jetzt bezahlen",
  "Pay with Flywire": "Mit Flywire bezahlen",
  "Flywire billing details": "Flywire Rechnungsdetails",
  "Billing": "Abrechnung",
  "First Name": "Vorname",
  "Last Name": "Nachname",
  "Email": "E-Mail",
  "Phone number": "Telefon-Nummer",
  "Address line 1": "Adresse Zeile 1",
  "Address line 2": "Adresse Zeile 2",
  "Country": "Land",
  "City": "Stadt",
  "Zip code": "Postleitzahl",
  "State": "Staat",
  "Awaiting payment confirmation": "Warten auf Zahlungsbestätigung",
  "We are awaiting confirmation of your payment.": "Wir warten auf die Bestätigung Ihrer Zahlung.",
  "You will be sent an email when your payment has been received successfully. Please select another method if you had problems making your payment.": "Sie erhalten eine E-Mail, wenn Ihre Zahlung erfolgreich eingegangen ist. Bitte wählen Sie eine andere Methode, wenn Sie Probleme bei der Zahlung hatten.",
  "Missing {{amount}}": "Fehlt {{amount}}",
  "Payment": "Zahlung",
  "There was an error in your submission, please try again in a few seconds. If the issue persists, please contact us.": "Bei Ihrer Eingabe ist ein Fehler aufgetreten, bitte versuchen Sie es in ein paar Sekunden erneut. Wenn das Problem weiterhin besteht, kontaktieren Sie uns bitte.",
  "{{ name }} (not currently available)": "{{ name }} (derzeit nicht verfügbar)",
  "{{ name }} (not available yet)": "{{ name }} (noch nicht verfügbar)",
  "Registrations are currently closed.": "Die Anmeldungen sind derzeit geschlossen.",
  "Registrations will open on {{ registrationsOpenDate }}.": "Anmeldungen werden am {{registrationsOpenDate}} geöffnet.",
  "Select an option": "Wählen Sie eine Option",
  "({{timezone}} time)": "({{timezone}} time)",
  "{{dateFullDay}} at {{dateTime}}": "{{dateFullDay}} um {{dateTime}}",
  "Product catalog": "Produktkatalog",
  "Search for a programme or course": "Suchen Sie nach einem Programm oder Kurs",
  "Start date": "Anfangsdatum",
  "End date": "Endtermin",
  "Duration": "Dauer",
  "Location": "Standort",
  "Price": "Preis",
  "Application deadline": "Bewerbungsschluss",
  "Registration deadline": "Anmeldeschluss",
  "Apply": "Bewerben",
  "Details": "Einzelheiten",
  "All": "Alle",
  "Now until the end of next month": "Heute bis Ende nächsten Monats",
  "In 1-6 months": "In 1 - 6 Monaten",
  "In 6-12 months": "In 6 - 12 Monaten",
  "In 1 year": "In 1 Jahr",
  "Theme": "Thema",
  "Pay with Stripe": "Bezahlen Sie mit Stripe",
  "Stripe payment details": "Stripe-Zahlungsdetails",
  "Your payment was processed": "Ihre Zahlung wurde verarbeitet",
  "Edit application": "Bewerbung bearbeiten",
  "Edit registration": "Anmeldung bearbeiten",
  "Have a discount code?": "Haben Sie einen Rabattcode?",
  "In order to submit you are required to pay a fee.": "Um einzureichen, müssen Sie eine Gebühr bezahlen.",
  "All fees paid": "Alle Gebühren bezahlt",
  "Next step:": "Nächster Schritt:",
  "Sign & Submit": "Unterschreiben und senden",
  "Submit": "Einreichen",
  "I accept the privacy policy": "Ich akzeptiere die Datenschutzerklärung",
  "Privacy Policy": "Datenschutzerklärung",
  "All fees paid.": "Alle Gebühren bezahlt.",
  "Next step.": "Nächster Schritt.",
  "Finish your application by clicking the button below.": "Schließen Sie Ihre Anwendung ab, indem Sie auf die Schaltfläche unten klicken.",
  "Finish your registration by clicking the button below.": "Schließen Sie Ihre Registrierung ab, indem Sie auf die Schaltfläche unten klicken.",
  "Check your inbox": "Überprüfen Sie Ihr Postfach",
  "A new link has been sent.": "Ein neuer Link wurde gesendet.",
  "Access expired": "Zugriff abgelaufen",
  "Click the button below to receive a new link in your inbox.": "Klicken Sie auf die Schaltfläche unten, um einen neuen Link in Ihrem Postfach zu erhalten.",
  "Send new link": "Neuen Link senden",
  "Payment link is invalid": "Zahlungslink ist ungültig",
  "This payment has been canceled or does not exist.": "Diese Zahlung wurde storniert oder existiert nicht.",
  "Fee has been paid": "Gebühr wurde bezahlt",
  "The requester has been notified.": "Der Antragsteller wurde benachrichtigt.",
  "On {{date}}, this fee was sent to an external payer.": "Diese Gebühr wurde am {{date}} an einen externen Zahler gesendet.",
  "Name is invalid": "Der Name ist ungültig",
  "Manage payer": "Zahler verwalten",
  "Send to payer": "An Zahler senden",
  "Cancel external payer": "Externen Zahler stornieren",
  "Resend fee details": "Gebührendetails erneut senden",
  "Send fee": "Gebühr senden",
  "An email containing all the payment details will be sent to the external payer.": "Der externe Zahler erhält eine E-Mail mit allen Zahlungsinformationen.",
  "Payer's email address": "E-Mail-Adresse des Zahlers",
  "Payer's name": "Name des Zahlers",
  "Actions": "Aktionen",
  "External payer access was revoked": "Der Zugang des externen Zahlers wurde widerrufen",
  "Fee was sent to an external payer": "Die Gebühr wurde an einen externen Zahler gesendet",
  "Fee paid": "Gebühr bezahlt"
}
