import 'css-chunk:src/apps/EmailTemplates/components/Attachments/styles.module.scss';export default {
  "react-app": "react-app",
  "uploadFile": "_uploadFile_16zt1_1",
  "uploadContainer": "_uploadContainer_16zt1_11",
  "uploadButton": "_uploadButton_16zt1_18",
  "filesList": "_filesList_16zt1_23",
  "leftInfo": "_leftInfo_16zt1_30",
  "withBorder": "_withBorder_16zt1_35",
  "content": "_content_16zt1_39",
  "filesAttachedTitle": "_filesAttachedTitle_16zt1_50",
  "filesListTitle": "_filesListTitle_16zt1_54"
};