import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { PlainButton, Text } from '@fullfabric/alma-mater'
import { useCurrentUser } from '@fullfabric/authorization-officer'

import { useLocale } from 'shared/contexts/Locale'

import { useDisplayFeedbackMessage } from 'apps/ContentPages/components/feeback-message-provider'
import useIsMobileLayout from 'apps/ContentPages/components/payments-page/use-is-payments-mobile-layout'
import { useOpenDetailsPaymentsDrawer } from 'apps/ContentPages/contexts/payments-drawer'
import useSettingByName from 'apps/ContentPages/hooks/use-setting-by-name'
import amountToPrice from 'apps/ContentPages/services/payments/amount-to-price'
import { formatDueDate } from 'apps/ContentPages/services/payments/format-due-date'
import getStateOfFee from 'apps/ContentPages/services/payments/get-state-of-fee'
import ExternalPayerRequestModal from '../external-payer-request-modal'
import FeesTable from '../fees-table'
import DueDateDetail from './fee-data-row/due-date-detail'
import ArrowIcon from './fee-data-row/icons/arrow-icon'
import StateBadge from './fee-data-row/state-badge'

import classNames from 'classnames'
import styles from './fee-data-row/styles.module.scss'

const FeeDataRow = ({ last, fee, paymentPlan, discounts, opts }) => {
  const { t } = useTranslation()
  const { locale } = useLocale()
  const queryClient = useQueryClient()
  const isInMobileLayout = useIsMobileLayout()
  const user = useCurrentUser()
  const isInDesktopLayout = !isInMobileLayout
  const [externalPayerRequestModalOpen, setExternalPayerRequestModalOpen] =
    useState(false)

  const displayFeedbackMessage = useDisplayFeedbackMessage()
  const openDetailsPaymentsDrawer = useOpenDetailsPaymentsDrawer()

  const stateOfFee = getStateOfFee(fee, {
    hideSecondaryFeeStatuses: !!opts.hideSecondaryFeeStatuses
  })
  const amountWithDiscount = Math.max(fee.total_amount, 0)
  const hasDiscount = amountWithDiscount !== fee.amount
  const amountWithoutDiscount = fee.amount
  const totalAmount = hasDiscount ? amountWithDiscount : amountWithoutDiscount
  const isPartiallyPaid =
    fee.outstanding_amount > 0 && fee.outstanding_amount < fee.total_amount

  const isPaid = stateOfFee === 'paid'
  const isPayable = paymentPlan?.payment_portal && paymentPlan?.is_payable

  const payButtonDisabled = !!opts.disableClickIfPaid && isPaid

  const externalPayersFeatureEnabled = useSettingByName(
    'modules.financial.features.external_payers.enable'
  )
  const isExternalPayerPayment = !!opts.externalPayerRequest
  const displayExternalPayerBtn =
    externalPayersFeatureEnabled && !isExternalPayerPayment && !isPaid

  const handleCallback = async (actionName) => {
    displayFeedbackMessage(
      actionName === 'revoke'
        ? t('External payer access was revoked')
        : t('Fee was sent to an external payer')
    )
    queryClient.invalidateQueries(['get-payment-plan', user.id])
    queryClient.invalidateQueries(['get-payment-plans', user.id])
    setExternalPayerRequestModalOpen(false)
  }

  const handlePayClick = () => {
    if (payButtonDisabled) return
    if (!isPaid && !isExternalPayerPayment && !!fee.external_payer_request) {
      setExternalPayerRequestModalOpen(true)
    } else if (opts.onFeeClick) {
      opts.onFeeClick({ fee, paymentPlan })
    } else {
      openDetailsPaymentsDrawer({ fee, discounts, paymentPlan })
    }
  }

  return (
    <>
      <FeesTable.Row last={last}>
        {isInDesktopLayout && !opts.hideDueDateColumn && (
          <FeesTable.Data className={styles.leftmostColumn}>
            <Text type='f4' fontColor='text-base-darkest'>
              {formatDueDate(fee.due_date, locale)}
            </Text>
            <DueDateDetail date={fee.due_date} state={stateOfFee} />
          </FeesTable.Data>
        )}
        <FeesTable.Data
          className={classNames(
            styles.descriptionColumn,
            opts.hideDueDateColumn && styles.leftmostColumn
          )}
        >
          <Text type='f4' fontColor='text-base-darkest'>
            {fee.description}
          </Text>
          {isInMobileLayout && !opts.hideDueDateColumn && (
            <Text type='f4' fontColor='text-base-darkest'>
              {formatDueDate(fee.due_date, locale)}
            </Text>
          )}
        </FeesTable.Data>
        <FeesTable.Data>
          <StateBadge
            stateOfFee={stateOfFee}
            hasPendingPayment={!!fee.has_pending_payment}
            hasExternalPayerRequest={!!fee.external_payer_request}
            isExternalPayerPayment={isExternalPayerPayment}
          />
        </FeesTable.Data>
        {isInDesktopLayout && (
          <FeesTable.Data className={styles.amountColumn}>
            <Text type='f4' fontColor='text-base-darkest'>
              {`${amountToPrice(fee.currency, totalAmount)}`}
            </Text>
            {isPartiallyPaid ? (
              <Text type='h5' fontColor='text-warning' marginTop='tiny'>
                {t('Missing {{amount}}', {
                  amount: amountToPrice(fee.currency, fee.outstanding_amount)
                })}
              </Text>
            ) : hasDiscount ? (
              <Text
                type='h5'
                fontColor='text-base'
                marginTop='tiny'
                className={styles.amountBeforeDiscount}
              >
                {`${amountToPrice(fee.currency, amountWithoutDiscount)}`}
              </Text>
            ) : null}
          </FeesTable.Data>
        )}
        {isPayable && (
          <FeesTable.Data>
            <div className={styles.actionsColumn}>
              {displayExternalPayerBtn && (
                <PlainButton
                  className={styles.actionsBtn}
                  secondary
                  onClick={() => setExternalPayerRequestModalOpen(true)}
                >
                  {fee.external_payer_request ? (
                    <Trans>Manage payer</Trans>
                  ) : (
                    <Trans>Send to payer</Trans>
                  )}
                </PlainButton>
              )}

              <PlainButton
                className={styles.actionsBtn}
                primary={!isPaid}
                secondary={isPaid}
                onClick={handlePayClick}
                disabled={payButtonDisabled}
              >
                {isPaid ? <Trans>Fee paid</Trans> : <Trans>Pay now</Trans>}
                {!isPaid && (
                  <PlainButton.RightIcon>
                    <ArrowIcon />
                  </PlainButton.RightIcon>
                )}
              </PlainButton>
            </div>
          </FeesTable.Data>
        )}
      </FeesTable.Row>
      {externalPayerRequestModalOpen && (
        <ExternalPayerRequestModal
          isOpen={externalPayerRequestModalOpen}
          onClose={() => setExternalPayerRequestModalOpen(false)}
          onAction={(action) => handleCallback(action)}
          payable={fee}
          readonly={isPaid}
        />
      )}
    </>
  )
}

export default FeeDataRow
