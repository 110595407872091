import React from 'react'
import { useParams } from 'react-router-dom'

import ApplicationContentsViewer from 'apps/Evaluations/components/ApplicationContentsViewer'
import EvaluationForm from 'apps/Evaluations/components/EvaluationForm'
import EvaluationPageHeader from 'apps/Evaluations/components/EvaluationPageHeader'
import UsefulInfoModal from 'apps/Evaluations/components/UsefulInfoModal'
import { ApplicationDataProvider } from 'apps/Evaluations/context/ApplicationData'
import { CountriesDataProvider } from 'apps/Evaluations/context/CountriesData'
import { EvaluationDataProvider } from 'apps/Evaluations/context/EvaluationData'
import { EvaluationFormDataProvider } from 'apps/Evaluations/context/EvaluationFormData'
import { EvaluationProfileDataProvider } from 'apps/Evaluations/context/EvaluationProfileData'
import useShiftShortcut from 'apps/Evaluations/utils/useShiftShortcut'

import styles from './styles.module.scss'

const SHORTCUT_KEY = 'a'

const EvaluationPage = () => {
  const { profileId, applicationId, evaluationId } = useParams()
  const formRef = React.useRef()
  const contentContainerRef = React.useRef()
  const [showApplication, setShowApplication] = React.useState(true)
  useShiftShortcut(
    SHORTCUT_KEY,
    React.useCallback(() => setShowApplication((isShowing) => !isShowing), [])
  )

  return (
    <CountriesDataProvider>
      <EvaluationDataProvider evaluationId={evaluationId}>
        <EvaluationProfileDataProvider profileId={profileId}>
          <ApplicationDataProvider applicationId={applicationId}>
            <EvaluationFormDataProvider>
              <div className={styles.evaluationContainer}>
                <EvaluationPageHeader
                  profileId={profileId}
                  applicationId={applicationId}
                  formRef={formRef}
                  showApplication={showApplication}
                  setShowApplication={setShowApplication}
                />

                <div className={styles.content} ref={contentContainerRef}>
                  <div className={styles.formContainer}>
                    <EvaluationForm
                      widened={!showApplication}
                      formRef={formRef}
                    />
                  </div>

                  <ApplicationContentsViewer
                    visible={showApplication}
                    containerRef={contentContainerRef}
                    applicationId={applicationId}
                  />
                </div>
              </div>

              <UsefulInfoModal />
            </EvaluationFormDataProvider>
          </ApplicationDataProvider>
        </EvaluationProfileDataProvider>
      </EvaluationDataProvider>
    </CountriesDataProvider>
  )
}

export default EvaluationPage
