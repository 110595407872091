import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { PageHead } from '@fullfabric/alma-mater'

import SuccessPopupMessage from 'shared/components/SuccessPopupMessage'
import TablePageLayout from 'shared/components/TablePageLayout'

import ContractsTable from './ContractsTable'
import CreateContractButton from './CreateContractButton'

const ContractsTablePage = () => {
  const { t } = useTranslation()

  const [feedbackMessageIsDisplayed, setFeedbackMessageIsDisplayed] =
    useState(false)

  return (
    <>
      <TablePageLayout>
        <PageHead
          noBorder
          title={<Trans>Billing</Trans>}
          action={
            <CreateContractButton
              onContractCreated={() => setFeedbackMessageIsDisplayed(true)}
            />
          }
        />

        <TablePageLayout.TableSection>
          <ContractsTable />
        </TablePageLayout.TableSection>
      </TablePageLayout>

      <SuccessPopupMessage
        isDisplayed={feedbackMessageIsDisplayed}
        onAfterTimeout={() => setFeedbackMessageIsDisplayed(false)}
        message={t('Contract created successfully')}
      />
    </>
  )
}

export default ContractsTablePage
