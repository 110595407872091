import React from 'react'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'

import { find } from 'lodash'

import getLocalizedText from 'shared/utils/getLocalizedText'

import getContracts from 'apps/Evaluations/api/getContracts'
import getContractSchema from 'apps/Evaluations/api/getContractSchema'
import getDiplomas from 'apps/Evaluations/api/getDiplomas'
import getDiplomaSchema from 'apps/Evaluations/api/getDiplomaSchema'
import {
  useApplication,
  useApplicationTemplate
} from 'apps/Evaluations/context/ApplicationData'
import { useEvaluationProfile } from 'apps/Evaluations/context/EvaluationProfileData'

export default function useViewOptions() {
  const applic = useApplication()?.data
  const applicTemplate = useApplicationTemplate()?.data
  const { data: profile } = useEvaluationProfile()

  const { data: contracts } = useQuery(
    ['contracts', profile?.id],
    () => getContracts(profile?.id),
    { enabled: applicTemplate?.enable_experience && !!profile }
  )

  const { data: diplomas } = useQuery(
    ['diplomas', profile?.id],
    () => getDiplomas(profile?.id),
    { enabled: applicTemplate?.enable_experience && !!profile }
  )

  const { data: contractSchema } = useQuery(
    'contractSchema',
    getContractSchema,
    { enabled: applicTemplate?.enable_experience }
  )

  const { data: diplomaSchema } = useQuery('diplomaSchema', getDiplomaSchema, {
    enabled: applicTemplate?.enable_experience
  })

  const viewOptions = React.useMemo(() => {
    const applicFieldMap = generateApplicFieldMap(applicTemplate, applic)

    const canIncludeExperienceFiles =
      applicTemplate?.enable_experience &&
      contracts &&
      diplomas &&
      contractSchema &&
      diplomaSchema

    const contractsFieldMap = canIncludeExperienceFiles
      ? generateContractsFieldMap(contracts, contractSchema)
      : {}

    const diplomasFieldMap = canIncludeExperienceFiles
      ? generateDiplomasFieldMap(diplomas, diplomaSchema)
      : {}

    return {
      applic: {
        fieldName: 'applic',
        optionKey: 'applic',
        label: <Trans>Application</Trans>,
        uploads: [
          {
            path: `applications/${applic.id}.pdf?exclude[evaluations]=true`
          }
        ]
      }, // default view
      ...applicFieldMap,
      ...contractsFieldMap,
      ...diplomasFieldMap
    }
  }, [
    applic,
    applicTemplate,
    contracts,
    contractSchema,
    diplomas,
    diplomaSchema
  ])

  return viewOptions
}

function generateApplicFieldMap(template, applic) {
  return (template?.documents || [])
    .filter(({ schema }) => !!schema) // filter empty schemas
    .flatMap(({ id, schema }) => {
      const applicDoc = find(applic.schemable_documents, ['document_id', id])
      return getFieldsWithUploads(applicDoc, schema)
    }) // applic document values (tab from applic) + schema
    .reduce(makeFieldMapReducer('applic'), {}) // merge fields and uploads in an "view option" object with "applic + field name + upload index" as key
}

function generateContractsFieldMap(contracts, schema) {
  return contracts
    .flatMap((contract) => getFieldsWithUploads(contract, schema))
    .reduce(makeFieldMapReducer('contract'), {})
}

function generateDiplomasFieldMap(diplomas, schema) {
  return diplomas
    .flatMap((diploma) => getFieldsWithUploads(diploma, schema))
    .reduce(makeFieldMapReducer('diploma'), {})
}

function getFieldsWithUploads(document, schema) {
  const allFileFields = schema.sections
    .flatMap((section) => section.fields)
    .filter((field) => field.type === 'Schemable::Fields::Types::File')
    .filter((field) => document[field.name]?.uploads?.length > 0)

  return allFileFields.map((field) => ({
    field,
    uploads: document[field.name].uploads
  }))
}

function makeFieldMapReducer(prefix) {
  return (map, { field, uploads }) => ({
    ...map,
    [`${prefix}-${field.name}`]: {
      fieldName: field.name,
      label: getLocalizedText(field.locals),
      uploads
    }
  })
}
