import React from 'react'
import { Trans } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import useIsMobileLayout from 'apps/ContentPages/components/payments-page/use-is-payments-mobile-layout'
import useSettingByName from '../hooks/use-setting-by-name'
import FeeDataRow from './fees-table/fee-data-row'

import classNames from 'classnames'
import styles from './fees-table/styles.module.scss'

const FeesTable = ({ fees, discounts, paymentPlan, opts = {} }) => {
  const sortedFees =
    fees && [...fees].sort((feeA, feeB) => feeA.due_date - feeB.due_date)

  const isInMobileLayout = useIsMobileLayout()
  const isInDesktopLayout = !isInMobileLayout

  const externalPayersFeatureEnabled = useSettingByName(
    'modules.financial.features.external_payers.enable'
  )
  const isExternalPayerPayment = !!opts.externalPayerRequest
  const isPayable = paymentPlan?.payment_portal && paymentPlan?.is_payable

  return (
    <table className={styles.table}>
      <Header>
        <Row borderless>
          {isInDesktopLayout && !opts.hideDueDateColumn && (
            <HeaderItem className={styles.leftmostColumn}>
              <Trans>Due Date</Trans>
            </HeaderItem>
          )}
          <HeaderItem
            className={classNames(
              opts.hideDueDateColumn && styles.leftmostColumn
            )}
          >
            <Trans>Description</Trans>
            {isInMobileLayout && !opts.hideDueDateColumn ? (
              <>
                {' '}
                / <Trans>Due Date</Trans>
              </>
            ) : null}
          </HeaderItem>
          <HeaderItem aria-label='fee state'>
            <Trans>Status</Trans>
          </HeaderItem>
          {isInDesktopLayout && (
            <>
              <HeaderItem className={styles.amountColumn}>
                <Trans>Amount</Trans>
              </HeaderItem>
            </>
          )}
          {isPayable && (
            <HeaderItem
              aria-label='fee details'
              className={classNames(
                styles.actionsColumn,
                externalPayersFeatureEnabled &&
                  !isExternalPayerPayment &&
                  styles.bigger
              )}
            >
              <Trans>Actions</Trans>
            </HeaderItem>
          )}
        </Row>
      </Header>
      <Body>
        {sortedFees?.map((fee, index) => (
          <FeeDataRow
            key={fee.id}
            fee={fee}
            paymentPlan={paymentPlan}
            discounts={discounts}
            last={index === sortedFees.length - 1}
            opts={opts}
          />
        ))}
      </Body>
    </table>
  )
}

const Header = ({ children }) => {
  return <thead className={styles.header}>{children}</thead>
}

const HeaderItem = ({ children, className, ...otherProps }) => {
  const isInMobileLayout = useIsMobileLayout()
  return (
    <Text
      tag='th'
      type='f5'
      fontColor='text-base-darker'
      className={classNames(
        styles.headerItem,
        isInMobileLayout && styles.mobile,
        className
      )}
      {...otherProps}
    >
      {children}
    </Text>
  )
}

const Row = ({ last, borderless, disabled, children }) => {
  return (
    <tr
      className={classNames(
        styles.row,
        (last || borderless) && styles.borderless
      )}
    >
      {children}
    </tr>
  )
}

const Body = ({ children }) => {
  return <tbody>{children}</tbody>
}

const Data = ({ children, className }) => {
  const isInMobileLayout = useIsMobileLayout()

  return (
    <td
      className={classNames(
        styles.tableData,
        isInMobileLayout && styles.mobile,
        className
      )}
    >
      {children}
    </td>
  )
}

FeesTable.Header = Header
FeesTable.HeaderItem = HeaderItem
FeesTable.Body = Body
FeesTable.Row = Row
FeesTable.Data = Data

export default FeesTable
