.react-app ._container_17xlk_1 {
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  justify-content: center;
  border-right: var(--border-base-lighter);
  border-left: 4px solid transparent;
  cursor: pointer;
}

.react-app ._active_17xlk_14,
.react-app ._container_17xlk_1:hover {
  background-color: var(--ff-bg-primary-lightest);
  border-left: 4px solid var(--ff-color-primary-600);
}

.react-app ._active_17xlk_14 {
  cursor: default;
}