import React from 'react'

import SidebarPanel from '../SidebarPanel'

export default function SettingsPanel({ active }) {
  return (
    <SidebarPanel active={active}>
      <div id='settings-container' className='panel' />
      <div id='styles-container' className='panel' />
    </SidebarPanel>
  )
}
