.react-app ._editor_bk70j_1 {
  width: 100%;
  height: 478px;
  outline: none;
  white-space: normal;
  padding: var(--space-small) var(--space-less);
  background: var(--ff-bg-base-lightest);
  border: var(--border-base-light);
  box-shadow: var(--shadow-inset);
  border-radius: var(--border-radius-default);
  color: var(--ff-color-text-base-darkest);
}
.react-app ._editor_bk70j_1 [contenteditable="true"] {
  outline: none;
}

.react-app ._container_bk70j_17 {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 73px);
  padding: 20px;
  overflow: auto;
}

.react-app ._editorContainer_bk70j_26 {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 100%;
  gap: var(--space-less);
}