.react-app ._container_dy7hg_1 {
  width: 320px;
  height: calc(100vh - 74px);
  flex-direction: column;
  padding: var(--space-default);
  display: none;
}

.react-app ._active_dy7hg_9 {
  display: flex;
}

.react-app ._noPadding_dy7hg_13 {
  padding: 0;
}