import React, { cloneElement } from 'react'

import { Text } from '@fullfabric/alma-mater'

import classNames from 'classnames'
import styles from './styles.module.scss'

export default function IconButton({ label, icon, tab, activeTab, onChange }) {
  const active = activeTab === tab

  return (
    <div
      className={classNames(styles.container, { [styles.active]: active })}
      onClick={() => onChange(tab)}
      data-testid={`tab-${tab}`}
    >
      {cloneElement(icon, { color: active ? 'primary-800' : 'grey-500' })}
      <Text
        type='h6'
        fontColor={active ? 'text-primary-darkest' : 'text-base-darker'}
      >
        {label}
      </Text>
    </div>
  )
}
