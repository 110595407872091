import React from 'react'
import { useTranslation } from 'react-i18next'

import { useLocale, useSetLocale } from 'shared/contexts/Locale'
import normalizeLocale from 'shared/utils/normalizeLocale'

import LinkPopUpFolder from 'apps/ContentPages/components/navigation/link-pop-up-folder'
import Link from '../link'
import WorldIcon from './world-icon'

export default function LocaleButton() {
  const { t } = useTranslation()
  const { locale: initialLocale, allLocales } = useLocale()
  const setLocale = useSetLocale()

  const onClick = (alternativeLocale) => (event) => {
    event.preventDefault()

    setLocale(alternativeLocale)
    location.reload() // eslint-disable-line no-restricted-globals
  }

  const allLinks = allLocales
    .filter((locale) => locale !== initialLocale)
    .map((locale) => ({
      navigation_label: t('Locale', {
        lng: normalizeLocale(locale)
      }),
      on_click: onClick(locale)
    }))

  return (
    <LinkPopUpFolder placement='right-end' links={allLinks}>
      <div>
        <Link
          data-testid='locale-button'
          large
          icon={<WorldIcon />}
          label={t('Locale')}
        />
      </div>
    </LinkPopUpFolder>
  )
}
