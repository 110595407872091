import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  AddCircleIcon,
  AttachmentIcon,
  StyleIcon
} from '@fullfabric/alma-mater'

import IconButton from '../IconButton'

import styles from './styles.module.scss'

export default function IconsContainer({ activeTab, onChange }) {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <IconButton
          activeTab={activeTab}
          tab='blocks'
          onChange={onChange}
          label={t('Content')}
          icon={<AddCircleIcon />}
        />
        <IconButton
          activeTab={activeTab}
          tab='style'
          onChange={onChange}
          label={t('Style')}
          icon={<StyleIcon />}
        />
        <IconButton
          activeTab={activeTab}
          tab='attachment'
          onChange={onChange}
          label={t('Attach')}
          icon={<AttachmentIcon />}
        />
      </div>
    </div>
  )
}
