import React from 'react'

import {
  AlignCenterIcon,
  AlignJustifyIcon,
  AlignLeftIcon,
  AlignRightIcon
} from '@fullfabric/alma-mater'

export const ActiveAlignIcon = ({ editor }) => {
  if (editor.isActive({ textAlign: 'right' })) {
    return <AlignRightIcon />
  } else if (editor.isActive({ textAlign: 'center' })) {
    return <AlignCenterIcon />
  } else if (editor.isActive({ textAlign: 'justify' })) {
    return <AlignJustifyIcon />
  }

  return <AlignLeftIcon />
}
