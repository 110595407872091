{
  "A verification email was sent to {{email}}.": "Se envió un correo electrónico de verificación a {{email}}.",
  "Accept": "Aceptar",
  "Application": "Solicitud",
  "Application options": "Opciones de solicitud",
  "Applications": "Solicitudes",
  "Applications are closed": "Las solicitudes están cerradas",
  "Calendar": "Calendario",
  "Cancel": "Cancelar",
  "Change to English": "Cambiar a español",
  "Locale": "Español",
  "Choose a campus": "Elija un campus",
  "Choose a class or course": "Elija una clase o curso",
  "Choose a programme or subject": "Elija un programa o tema",
  "Close": "Cerrar",
  "Close navigation": "Cerrar la navegación",
  "Closes {{ dateAndTimeText }}": "Se cierra {{dateAndTimeText}}",
  "Conditional offer": "Oferta condicional",
  "Continue": "Continuar",
  "Continue application": "Continuar la solicitud",
  "Continue registration": "Continuar el registro",
  "Data Protection Regulation": "Reglamento de protección de datos",
  "Decline": "Rechazar",
  "Email address": "Email",
  "Email address is invalid": "La dirección de correo electrónico no es válida",
  "Email already in use": "Alguien ya registró esa dirección de correo electrónico.",
  "Email failed!": "Correo electrónico ha fallado!",
  "Email not verified": "Email no verificado",
  "Email sent": "Email enviado",
  "First name": "Nombre",
  "Hide map": "Ocultar mapa",
  "Home": "Inicio",
  "In Progress": "En progreso",
  "Last name": "Apellido",
  "Learn more": "Aprende más",
  "Loading": "Cargando",
  "Login": "Identificarse",
  "Logout": "Cerrar sesión",
  "Main Menu": "Menú principal",
  "Menu": "Menú",
  "My Applications": "Mis solicitudes",
  "My Registrations": "Mis Registros",
  "Name": "Nombre",
  "No applications available": "No hay aplicaciones disponibles",
  "No events": "No hay eventos",
  "No registrations available": "No hay registros disponibles",
  "Offers": "Ofertas",
  "Opens {{ dateAndTimeText }}": "Abre {{dateAndTimeText}}",
  "Other Content": "Otro contenido",
  "Participant details": "Datos del participante",
  "Please check your email.": "Por favor revise su correo electrónico.",
  "Please enter the details of the participant": "Introduzca los datos del participante",
  "Please fill out the form": "Por favor, rellene el formulario",
  "Please select an option": "Por favor, seleccione una opción",
  "Public profile": "Perfil público",
  "Register": "Registrarse",
  "Registration": "Registro",
  "Registration options": "Opciones de registro",
  "Registrations": "Registros",
  "Registrations are closed": "Los registros están cerrados",
  "Registrations are closed.": "Las inscripciones están cerradas.",
  "Resend verification email": "Reenviar correo electrónico de verificación",
  "Settings": "Ajustes",
  "Show map": "Mostrar mapa",
  "Skip to main content": "Saltar al contenido principal",
  "Someone already registered with that email address": "Alguien ya está registrado con esa dirección de correo electrónico",
  "Start another application": "Iniciar otra aplicación",
  "Start another registration": "Iniciar otro registro",
  "Start application": "Comenzar solicitud",
  "Start registration": "Iniciar registro",
  "Submitted": "Enviada",
  "Thank you for filling in the form.": "Gracias por completar el formulario.",
  "This event has now passed.": "Este evento ya pasó.",
  "This event is now full.": "Este evento ahora está completo.",
  "This field is required": "Este campo es obligatorio",
  "To access applications please register or login.": "Para acceder a las solicitudes, por favor regístrese o inicie sesión.",
  "To access registrations please register or login.": "Para acceder a las inscripciones, por favor regístrese o inicie sesión.",
  "Transcripts": "Transcripciones",
  "Unconditional offer": "Oferta incondicional",
  "Upcoming Events": "Próximos Eventos",
  "View Submission": "Ver sumisión",
  "View application": "Ver solicitud",
  "View registration": "Ver registro",
  "You can change your selection later.": "Puedes cambiar tu selección más tarde.",
  "You haven't started or submitted a registration yet": "Aún no ha comenzado o presentado un registro.",
  "You haven't started or submitted an application yet": "Aún no ha comenzado o presentado una solicitud.",
  "You will receive an email shortly.": "Recibirás un correo electrónico en breve.",
  "on {{ date }}": "en {{date}}",
  "on {{ date }} at {{ time }}": "en {{date}} a {{time}}",
  "today at {{ time }}": "hoy a la {{time}}",
  "tomorrow at {{ time }}": "mañana a la {{time}}",
  "{{ name }} (closed)": "{{name}} (cerrado)",
  "Payments": "Pagos",
  "View your fees and make payments": "Ver sus honorarios y realizar pagos",
  "Paid <1></1>": "Pagado <1></1>",
  "Due in {{timeToDueDate}}": "Debido en {{timeToDueDate}}",
  "Due today": "Para hoy",
  "{{timeFromDueDate}} overdue": "{{timeFromDueDate}} atrasado",
  "Due Date": "Fecha de vencimiento",
  "Status": "Estado",
  "Description": "Descripción",
  "Amount": "Monto",
  "Payment plan for": "Plan de pago para",
  "Alerts": "Alertas",
  "Next payment:": "Siguiente pago:",
  "See details": "Ver detalles",
  "Discount": "Descuento",
  "Total": "Total",
  "Paid": "Pagado",
  "Awaiting confirmation": "Esperando confirmación",
  "Due soon": "Pronto vencido",
  "Due / Overdue": "Pendiente / Atrasado",
  "Back to list": "Volver a la lista",
  "Fee details": "Detalles de honorarios",
  "Payment Plans": "Planes de pago",
  "Pay now": "Pagar ahora",
  "Pay with Flywire": "Pago con Flywire",
  "Flywire billing details": "Datos de facturación de Flywire",
  "Email": "Correo electrónico",
  "Phone number": "Número de teléfono",
  "Address line 1": "Dirección línea 1",
  "Address line 2": "Dirección línea 2",
  "Country": "País",
  "City": "Ciudad",
  "Zip code": "Código postal",
  "State": "Estado",
  "Awaiting payment confirmation": "Esperando confirmación de pago",
  "We are awaiting confirmation of your payment.": "Estamos esperando la confirmación de su pago.",
  "You will be sent an email when your payment has been received successfully. Please select another method if you had problems making your payment.": "Se le enviará un correo electrónico cuando su pago se haya recibido correctamente. Seleccione otro método si tuvo problemas para realizar su pago.",
  "Missing {{amount}}": "Falta {{amount}}",
  "Payment": "Pago",
  "There was an error in your submission, please try again in a few seconds. If the issue persists, please contact us.": "Ha habido un error en su envío, por favor inténtelo de nuevo en unos segundos. Si el problema persiste, póngase en contacto con nosotros.",
  "{{ name }} (not currently available)": "{{ name }} (no disponible actualmente)",
  "{{ name }} (not available yet)": "{{ name }} (aun no está disponible)",
  "Registrations are currently closed.": "Actualmente las inscripciones están cerradas.",
  "Registrations will open on {{ registrationsOpenDate }}.": "Las inscripciones se abrirán el {{registrationsOpenDate}}.",
  "Select an option": "Seleccione una opción",
  "({{timezone}} time)": "({{timezone}} time)",
  "{{dateFullDay}} at {{dateTime}}": "{{dateFullDay}} a las {{dateTime}}",
  "Product catalog": "Catalogo de productos",
  "Search for a programme or course": "Buscar un programa o curso",
  "Start date": "Fecha de inicio",
  "End date": "Fecha final",
  "Duration": "Duración",
  "Location": "Ubicación",
  "Price": "Precio",
  "Application deadline": "Plazo de solicitud",
  "Registration deadline": "Plazo de registro",
  "Apply": "Aplicar",
  "Details": "Detalles",
  "All": "Todas",
  "Now until the end of next month": "Desde hoy hasta finales del próximo mes",
  "In 1-6 months": "En 1 - 6 meses",
  "In 6-12 months": "En 6 - 12 meses",
  "In 1 year": "En 1 año",
  "Theme": "Tema",
  "Pay with Stripe": "Paga con Stripe",
  "Stripe payment details": "Detalles de pago Stripe",
  "Your payment was processed": "Su pago fue procesado",
  "Edit application": "Editar aplicación",
  "Edit registration": "Editar registro",
  "Have a discount code?": "¿Tiene un código de descuento?",
  "In order to submit you are required to pay a fee.": "Para enviar, es necesario pagar una tarifa.",
  "All fees paid": "Todas las tarifas pagadas",
  "Next step:": "Siguiente paso:",
  "Sign & Submit": "Firmar y enviar",
  "Submit": "Enviar",
  "I accept the privacy policy": "Acepto la política de privacidad",
  "Privacy Policy": "Política de privacidad",
  "All fees paid.": "Todos los honorarios pagados.",
  "Next step.": "Próximo paso.",
  "Finish your application by clicking the button below.": "Termina tu aplicación haciendo clic en el botón de abajo.",
  "Finish your registration by clicking the button below.": "Finaliza tu registro haciendo clic en el botón de abajo.",
  "Check your inbox": "Verifica tu bandeja de entrada",
  "A new link has been sent.": "Se ha enviado un nuevo enlace.",
  "Access expired": "Acceso caducado",
  "Click the button below to receive a new link in your inbox.": "Haz clic en el botón de abajo para recibir un nuevo enlace en tu bandeja de entrada.",
  "Send new link": "Enviar nuevo enlace",
  "Payment link is invalid": "El enlace de pago no es válido",
  "This payment has been canceled or does not exist.": "Este pago ha sido cancelado o no existe.",
  "Fee has been paid": "La tarifa ha sido pagada",
  "The requester has been notified.": "El solicitante ha sido notificado.",
  "On {{date}}, this fee was sent to an external payer.": "Esta tarifa se envió a un pagador externo el {{date}}.",
  "Name is invalid": "El nombre no es válido",
  "Manage payer": "Gestionar pagador",
  "Send to payer": "Enviar al pagador",
  "Cancel external payer": "Cancelar pagador externo",
  "Resend fee details": "Reenviar datos de la tarifa",
  "Send fee": "Enviar tarifa",
  "An email containing all the payment details will be sent to the external payer.": "Se enviará un correo electrónico al pagador externo con toda la información sobre el pago.",
  "Payer's email address": "Dirección de correo electrónico del pagador",
  "Payer's name": "Nombre del pagador",
  "Actions": "Medidas",
  "External payer access was revoked": "Se ha revocado el acceso a pagadores externos",
  "Fee was sent to an external payer": "Se ha enviado la tarifa a un pagador externo",
  "Fee paid": "Tarifa pagada"
}
