import React, { useCallback, useEffect, useRef } from 'react'

import { Dropdown, IconButton } from '@fullfabric/alma-mater'

import DropDownMergeTags from '../../DropdownMergeTags'

import styles from './styles.module.scss'

const getPreviousNode = (evt) => {
  const selection = evt.view.getSelection()
  const range = selection.getRangeAt(0)
  const nodeAtCursor = range.startContainer
  const offset = range.startOffset

  if (nodeAtCursor.nodeType === Node.TEXT_NODE) {
    if (offset === 0) {
      return nodeAtCursor.previousSibling
    } else if (offset === nodeAtCursor.length) {
      return nodeAtCursor.nextSibling
    }
  } else {
    return nodeAtCursor.childNodes[offset - 1]
  }
}

export const MergeTags = ({ editor, mergeTags }) => {
  const [open, setOpen] = React.useState(false)
  const currentElement = useRef(null)

  const onOpenDropdownMenu = useCallback(
    (ev) => {
      ev.stopPropagation()
      ev.preventDefault()
      setOpen(!open)
    },
    [open]
  )

  const onKeyDown = function (evt) {
    const previousNode = getPreviousNode(evt)

    if (evt.keyCode === 8 && previousNode?.nodeName === 'MERGE-TAG') {
      evt.preventDefault()
      evt.stopPropagation()

      editor
        .getWrapper()
        .find('#' + previousNode.id)
        .at(0)
        .remove()

      return false
    }
  }

  useEffect(() => {
    const onDisableEditor = () => {
      currentElement.current.removeEventListener('keydown', onKeyDown)
      setOpen(false)
    }
    const onEnableEditor = (childView) => {
      currentElement.current = childView.getChildrenContainer()
      currentElement.current.addEventListener('keydown', onKeyDown)
    }

    editor.on('rte:disable', onDisableEditor)
    editor.on('rte:enable', onEnableEditor)

    return () => {
      editor.off('rte:disable', onDisableEditor)
      editor.off('rte:enable', onEnableEditor)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor])

  const onAddTag = useCallback(
    (event, mergeTag) => {
      event.preventDefault()
      setOpen(false)

      // Actually inserts the merge tag into the editor
      editor.RichTextEditor.globalRte.insertHTML(
        `<merge-tag value="${mergeTag.value}" label="${mergeTag.name}">${mergeTag.name}</merge-tag>`
      )

      // Trigger the editor to update the components and treat the merge tag as a component
      editor
        .getSelected()
        .components()
        .resetFromString(
          editor.getSelected().getView().getChildrenContainer().innerHTML
        )
    },
    [editor]
  )

  return (
    <>
      <IconButton small onMouseDown={onOpenDropdownMenu}>
        {`{}`}
      </IconButton>
      <Dropdown.Menu
        className={styles.menu}
        style={{ display: open ? 'block' : 'none' }}
      >
        <DropDownMergeTags mergeTags={mergeTags} onAddTag={onAddTag} />
      </Dropdown.Menu>
    </>
  )
}
