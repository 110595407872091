import React, { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { noop, sortBy } from 'lodash'

import { Badge } from '@fullfabric/alma-mater'
import {
  AuthorizationOfficer,
  useCurrentUser,
  UserRoleUtils,
  useSettings
} from '@fullfabric/authorization-officer'

import getRoles from 'shared/api/authorization/getRoles'
import getStaff from 'shared/api/staff/getStaff'
import getTeams from 'shared/api/teams/getTeams'
import AutoloadDataTable from 'shared/components/AutoloadDataTable'
import cellStyles from 'shared/components/cells/Cells.module.scss'

import DateTimeCell from './UsersTable/DateTimeCell'
import RoleCell from './UsersTable/RoleCell'
import StatusCell from './UsersTable/StatusCell'
import TeamsCell from './UsersTable/TeamsCell'

import styles from './UsersTable.module.scss'

const FILTER_STORAGE_KEY = 'staff-users-table:filter:v1'

const TEAMS_FILTER_SPEC = {
  id: 'teams',
  name: 'Teams',
  labelValueSeparator: ':',
  type: 'multi'
}

const ROLES_FILTER_SPEC = {
  id: 'roles',
  name: 'Role',
  labelValueSeparator: ':',
  type: 'multi'
}

const STATUS_FILTER_SPEC = {
  id: 'statuses',
  name: 'Status',
  labelValueSeparator: ':',
  type: 'multi',
  options: [
    {
      label: 'Active',
      value: 'user::active'
    },
    {
      label: 'Inactive',
      value: 'user::inactive'
    },
    {
      label: 'Suspended',
      value: 'user::suspended'
    }
  ]
}

export default function UsersTable() {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const settings = useSettings()

  const isSupport = UserRoleUtils.isSupport(user)
  const showTeams = hasFeature('core.profiles', 'teams', settings)
  const showSmtp = hasFeature('core.emails', 'user_smtp', settings)
  const showImap = hasFeature('core.emails', 'user_imap', settings)

  const { columns, filters } = useUserColumnsFilters(isSupport, {
    showTeams,
    showSmtp,
    showImap
  })

  const staffQueryParams = { include: { smtp: showSmtp, imap: showImap } }
  if (!isSupport) staffQueryParams.excludeSupport = true

  return (
    <AutoloadDataTable
      autoPaginate
      fixedLayout
      manipulationSectionClassName={styles.usersTable}
      fetchFn={getStaff}
      fetchParams={staffQueryParams}
      reactQueryProps={{ staleTime: 0 }}
      emptyMessage={t('No data')}
      columns={columns}
      filterable
      filterStorageKey={FILTER_STORAGE_KEY}
      filters={filters}
      loadMoreProps={{
        sizeKey: 'limit',
        offsetKey: 'offset'
      }}
      searchable
      searchParamKey='q'
      sortable
      sortParams={{
        sortKey: 'sort[field]',
        directionKey: 'sort[direction]',
        ascValue: 1,
        descValue: -1
      }}
      initialSort={{
        'sort[field]': '_search_name',
        'sort[direction]': 1
      }}
      tableProps={{
        getRowId
      }}
    />
  )
}

function useUserColumnsFilters(
  userIsSupport,
  { showTeams, showSmtp, showImap }
) {
  const { t } = useTranslation()

  const teamsPromiseRef = useRef(Promise.withResolvers())
  const rolesPromiseRef = useRef(Promise.withResolvers())

  const rolesQuery = useQuery('getRoles', getRoles)
  const teamsQuery = useQuery(['teams', { 'sort[field]': 'name' }], () =>
    getTeams({ 'sort[field]': 'name' })
  )

  const roles = useMemo(
    () => rolesQuery.data && visibleRoles(rolesQuery.data, userIsSupport),
    [rolesQuery.data, userIsSupport]
  )

  const StatelessTeamsCell = useMemo(() => {
    return (props) => (
      <TeamsCell
        {...props}
        teams={teamsQuery.data?.teams}
        isLoading={teamsQuery.isLoading}
      />
    )
  }, [teamsQuery.data, teamsQuery.isLoading])

  const StatelessRoleCell = useMemo(() => {
    return (props) => (
      <RoleCell {...props} roles={roles} isLoading={rolesQuery.isLoading} />
    )
  }, [roles, rolesQuery.isLoading])

  useEffect(() => {
    if (teamsQuery.isLoading) return
    if (teamsQuery.isError) teamsPromiseRef.current.reject(teamsQuery.error)
    if (teamsQuery.data)
      teamsPromiseRef.current.resolve(teamOptions(teamsQuery.data.teams))
  }, [
    teamsQuery.isLoading,
    teamsQuery.isError,
    teamsQuery.data,
    teamsQuery.error
  ])

  useEffect(() => {
    if (rolesQuery.isLoading) return
    if (rolesQuery.isError) rolesPromiseRef.current.reject(rolesQuery.error)
    if (roles) rolesPromiseRef.current.resolve(rolesOptions(roles))
  }, [rolesQuery.isLoading, rolesQuery.isError, roles, rolesQuery.error])

  const columns = [
    {
      Header: t('Name'),
      id: '_search_name',
      accessor: 'full_name',
      linkTo: ({ row, data, _value }) => ({
        href: `/profiles/${data[row.index].id}`,
        target: '_parent'
      })
    },
    {
      Header: t('Email'),
      id: '_search_email',
      accessor: 'email'
    },
    {
      Header: t('Status'),
      id: '_sort_user_substate',
      accessor: 'roles',
      Cell: StatusCell,
      getTitle: noop
    }
  ]

  if (showTeams) {
    columns.push({
      Header: t('Teams'),
      id: '_sort_team_name',
      accessor: 'teams_ids',
      Cell: StatelessTeamsCell,
      getTitle: noop
    })
  }

  columns.push({
    Header: t('Role'),
    id: '_sort_staff_substate',
    accessor: 'roles',
    Cell: StatelessRoleCell,
    getTitle: noop
  })

  if (showSmtp) {
    columns.push({
      Header: t('SMTP'),
      accessor: 'smtp_settings',
      Cell: ({ value }) =>
        value?.active ? (
          <Badge className={cellStyles.tableBadge} type='blue'>
            Active
          </Badge>
        ) : (
          <Badge className={cellStyles.tableBadge}>Inactive</Badge>
        ),
      disableSortBy: true,
      width: 80
    })
  }

  if (showImap) {
    columns.push({
      Header: t('IMAP'),
      accessor: 'imap_settings',
      Cell: ({ value }) =>
        value?.active ? (
          <Badge className={cellStyles.tableBadge} type='blue'>
            Active
          </Badge>
        ) : (
          <Badge className={cellStyles.tableBadge}>Inactive</Badge>
        ),
      disableSortBy: true,
      width: 80
    })
  }

  columns.push({
    Header: t('Last engaged'),
    accessor: 'last_seen',
    Cell: DateTimeCell,
    getTitle: noop
  })

  const filters = [
    STATUS_FILTER_SPEC,
    {
      ...TEAMS_FILTER_SPEC,
      loadOptions: () => teamsPromiseRef.current.promise
    },
    {
      ...ROLES_FILTER_SPEC,
      loadOptions: () => rolesPromiseRef.current.promise
    }
  ]

  return { columns, filters }
}

function teamOptions(teams) {
  return teams.map(({ id, name }) => ({ value: id, label: name }))
}

function rolesOptions(roles) {
  return roles.map(({ name }) => ({ value: name, label: name }))
}

function visibleRoles(roles, isSupport) {
  const visibleRoles = roles.filter(
    ({ name }) =>
      /^staff::/.test(name) && (isSupport || !/::support$/.test(name))
  )

  return sortBy(visibleRoles, 'name')
}

function hasFeature(module, feature, settings) {
  return AuthorizationOfficer.moduleHasFeature(module, feature, settings)
}

function getRowId(row, _relativeIndex, _parent) {
  return `profile-${row.id}-row` // profile ID
}
