import React from 'react'
import { useTranslation } from 'react-i18next'

import AutoloadDataTable from 'shared/components/AutoloadDataTable'

import { CONTRACT_DETAILS_ROUTE } from 'apps/Billing/components/AppRoutes'
import getContractName from 'apps/Billing/utils/getContractName'
import getPeriodicityLabel from 'apps/Billing/utils/getPeriodicityLabel'
import getNumberCellValue from './utils/getNumberCellValue'
import getTransactionsCellValue from './utils/getTransactionsCellValue'

export default function ContractsTable() {
  const { t } = useTranslation()

  return (
    <AutoloadDataTable
      fetchURL={'/billing/api/contracts'}
      emptyMessage={t('No data')}
      columns={[
        {
          linkTo: ({ row, data }) => ({
            to: CONTRACT_DETAILS_ROUTE.replace(
              ':contractId',
              data[row.index].id
            )
          }),
          Header: t('Contract'),
          id: 'contract-name-column',
          accessor: (row) => getContractName(row),
          type: 'primary'
        },
        {
          Header: t('Periodicity'),
          id: 'billing-periodicity-column',
          accessor: (row) => getPeriodicityLabel(row.billing_periodicity, t),
          type: 'text'
        },
        {
          Header: t('Total profiles'),
          accessor: 'stats.profiles.total',
          type: 'number',
          Cell: getNumberCellValue
        },
        {
          Header: t('Engaged profiles'),
          accessor: 'stats.profiles.engaged',
          type: 'number',
          Cell: getNumberCellValue
        },
        {
          Header: t('Student profiles'),
          accessor: 'stats.students.total',
          type: 'number',
          Cell: getNumberCellValue
        },
        {
          Header: t('Emails'),
          accessor: 'stats.emails.total',
          type: 'number',
          Cell: getNumberCellValue
        },
        {
          Header: t('Applications'),
          accessor: 'stats.applications.total',
          type: 'number',
          Cell: getNumberCellValue
        },
        {
          Header: t('Transactions'),
          accessor: 'stats.transactions.total',
          type: 'number',
          Cell: getTransactionsCellValue
        }
      ]}
    />
  )
}
