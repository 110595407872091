import { utils } from '@fullfabric/public-api'

export default async function editBillingEvent(id, isBillable) {
  const response = await fetch(`/billing/api/events/${id}`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ billable: isBillable })
  })

  return await utils.checkResponse(response)
}
