import React, { useCallback, useEffect } from 'react'

import { debounce } from 'lodash'

import { TextareaInput } from '@fullfabric/alma-mater'

import Attachments from '../Attachments'

import styles from './styles.module.scss'

export const HtmlEditorEditor = ({ emailTemplate, onSave, onChange }) => {
  const [internalValue, setInternalValue] = React.useState()
  const onEditorSave = debounce(onSave, 500)

  useEffect(() => {
    setInternalValue(emailTemplate.html)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onUpdate = useCallback(
    (ev) => {
      setInternalValue(ev.target.value)
      onChange()

      onEditorSave({ html: ev.target.value })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange]
  )

  return (
    <div className={styles.container}>
      <div className={styles.editorContainer}>
        <TextareaInput
          value={internalValue || ''}
          onChange={onUpdate}
          className={styles.editor}
        />

        <Attachments
          attachments={emailTemplate.attachments || []}
          templateId={emailTemplate.id}
        />
      </div>
    </div>
  )
}
