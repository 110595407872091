import { Node } from '@tiptap/core'

export const MergeTagNode = Node.create({
  name: 'merge-tag',
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,
  addAttributes() {
    return {
      value: {
        default: null
      },
      label: {
        default: null
      }
    }
  },
  parseHTML() {
    return [{ tag: `merge-tag` }]
  },
  renderHTML({ node }) {
    return [
      'merge-tag',
      Object.assign(node.attrs, {
        style: 'border: 1px dashed lightblue; padding: 2px 5px'
      }),
      node.attrs.label
    ]
  }
})

export default MergeTagNode
