import React from 'react'

import ClassicEditor from '../../ClassicEditor'
import GrapejsEditor from '../../Grapejs'
import { HtmlEditorEditor } from '../../HtmlEditor'
import { PlainTextEditor } from '../../PlainTextEditor'
import UnlayerEditor from '../../UnlayerEditor'

const getEditor = (editor) => {
  switch (editor) {
    case 'unlayer':
      return UnlayerEditor
    case 'grapejs':
      return GrapejsEditor
    case 'classic':
      return ClassicEditor
    case 'plain_text':
      return PlainTextEditor
    case 'html':
      return HtmlEditorEditor
    default:
      return null
  }
}
export default function InternalEditor({
  template,
  onSave,
  classicEditorRef,
  handleEmailChanged
}) {
  if (template.editor !== 'classic') {
    const Editor = getEditor(template.editor)

    return (
      <Editor
        emailTemplate={template}
        onSave={onSave}
        onChange={handleEmailChanged}
      />
    )
  } else {
    return <ClassicEditor id={template.id} ref={classicEditorRef} />
  }
}
