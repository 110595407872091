import React from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '@fullfabric/alma-mater'

import styles from './styles.module.scss'

export default function EmptyState() {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <Text type='f5' fontColor='text-base-darker'>
        {t('Empty - No files attached')}
      </Text>
    </div>
  )
}
