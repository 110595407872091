.react-app ._container_1be9j_1 {
  display: flex;
  justify-content: space-between;
}

.react-app ._editorMainActions_1be9j_6,
.react-app ._undoRedoActions_1be9j_7 {
  display: flex;
  align-items: center;
  gap: var(--space-small);
}

.react-app ._mergeTagButton_1be9j_13 {
  color: var(---ff-color-text-base-darkest);
}