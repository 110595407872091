import React, { useState } from 'react'

import AttachPanel from './AttachPanel'
import BlocksPanel from './BlocksPanel'
import IconsContainer from './IconsContainer'
import SettingsPanel from './SettingsPanel'

import styles from './styles.module.scss'

export const Sidebar = ({ editor, emailTemplate }) => {
  const [active, setActive] = useState('blocks')

  return (
    <div className={styles.container}>
      <IconsContainer activeTab={active} onChange={setActive} />
      <BlocksPanel active={active === 'blocks'} editor={editor} />
      <SettingsPanel active={active === 'style'} />
      <AttachPanel
        active={active === 'attachment'}
        emailTemplate={emailTemplate}
      />
    </div>
  )
}
