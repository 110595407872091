.react-app ._uploadFile_16zt1_1 {
  opacity: 0;
  top: 0;
  left: 0px;
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: absolute !important;
}

.react-app ._uploadContainer_16zt1_11 {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}

.react-app ._uploadButton_16zt1_18 {
  overflow: hidden;
  position: relative;
}

.react-app ._filesList_16zt1_23 {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: var(--space-less);
}

.react-app ._leftInfo_16zt1_30 {
  display: flex;
  flex-direction: column;
}

.react-app ._withBorder_16zt1_35 {
  border-bottom: var(--border-base-lighter);
}

.react-app ._content_16zt1_39 {
  font-size: var(--gjs-font-size);
  padding: var(--space-default);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  box-sizing: border-box;
  width: 100%;
  justify-content: flex-start;
}

.react-app ._filesAttachedTitle_16zt1_50 {
  margin-bottom: var(--space-less);
}

.react-app ._filesListTitle_16zt1_54 {
  display: flex;
  flex-direction: column;
}