import React, { useCallback, useRef, useState } from 'react'

import FullscreenV2 from 'shared/components/FullscreenV2'

import { useEmailTemplate } from 'apps/EmailTemplates/hooks/useEmailTemplate'
import { useEmailTemplateUpdate } from 'apps/EmailTemplates/hooks/useEmailTemplateUpdate'
import { usePreventLeaveIfSaving } from 'apps/EmailTemplates/hooks/usePreventLeaveIfSaving'
import EditorHeader from '../EditorHeader'
import InternalEditor from './InternalEditor'

function waitForClassicSaving(iframe) {
  return new Promise((resolve) => {
    // Just to make sure that the action is triggered and in execution
    setTimeout(() => {
      const submitButton = iframe.contentDocument.querySelector('.submit')
      const saveButton = iframe.contentDocument.querySelector('.save')

      ;(submitButton || saveButton)?.click?.()
      ;(function fnWaitForSave() {
        const modalFound = iframe.contentDocument.querySelector('.modal')
        if (modalFound || saveButton?.textContent.match(/saving/i)) {
          setTimeout(fnWaitForSave)
        } else {
          resolve()
        }
      })()
    }, 500)
  })
}
export default function EmailEditor({
  id,
  context,
  onClickBack,
  customTitle,
  isEmbedded,
  disableFullScreen,
  disableHeader
}) {
  const [classicEditorSaving, setClassicEditorSaving] = useState(false)
  const classicEditorRef = useRef()
  const hasChanges = useRef(false)
  const { data, refetch, isLoading } = useEmailTemplate(id)
  const { mutate, isLoading: saving } = useEmailTemplateUpdate(id)

  usePreventLeaveIfSaving(hasChanges.current)

  const onSave = useCallback(
    (data) => mutate(data, { onSettled: () => (hasChanges.current = false) }),
    [mutate]
  )

  const handleEmailChanged = useCallback(() => (hasChanges.current = true), [])

  const onSaveAndExit = useCallback(async () => {
    // When using the classic editor we still need to trigger the save button
    if (data.editor === 'classic') {
      setClassicEditorSaving(true)

      await waitForClassicSaving(classicEditorRef.current)
    }
    if (onClickBack) {
      onClickBack?.()
    } else {
      window.top.location.assign('/shared_email_templates')
    }
  }, [onClickBack, data])

  const editorHeader = !disableHeader && (
    <EditorHeader
      id={id}
      title={data?.name}
      subject={data?.default_subject}
      refetch={refetch}
      saving={saving || classicEditorSaving}
      onClickBack={onSaveAndExit}
      customTitle={customTitle}
      context={context}
      isEmbedded={isEmbedded}
    />
  )

  if (disableFullScreen) {
    return (
      <>
        {editorHeader}
        {!isLoading && (
          <InternalEditor
            template={data}
            onSave={onSave}
            classicEditorRef={classicEditorRef}
            handleEmailChanged={handleEmailChanged}
          />
        )}
      </>
    )
  }

  return (
    <FullscreenV2 isLoading={isLoading}>
      {editorHeader}
      {!isLoading && (
        <InternalEditor
          template={data}
          onSave={onSave}
          classicEditorRef={classicEditorRef}
          handleEmailChanged={handleEmailChanged}
        />
      )}
    </FullscreenV2>
  )
}
