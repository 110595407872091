import React from 'react'

import ApplicationPdfViewer from './components/ApplicationPdfViewer'
import ViewControl from './components/ViewControl'
import ZoomControl from './components/ZoomControl'
import useViewOptions from './hooks/useViewOptions'
import getViewerComponentForFile from './utils/getViewerComponentForFile'

import cx from 'classnames'
import styles from './styles.module.scss'

export default function ApplicationContentsViewer({ visible, containerRef }) {
  const [scale, setScale] = React.useState(1)
  const [selectedView, setSelectedView] = React.useState('applic')
  const [selectedUploadIndex, setSelectedUploadIndex] = React.useState(0)
  const viewOptions = useViewOptions()
  const onChangeSelectedView = React.useCallback((view) => {
    setSelectedView(view)
    setScale(1)
    setSelectedUploadIndex(0)
  }, [])

  const selectedViewOption = viewOptions[selectedView]
  const upload = selectedViewOption?.uploads[selectedUploadIndex]
  const ViewComponent =
    getViewerComponentForFile(upload?.filename) || ApplicationPdfViewer

  return (
    <div
      className={cx(styles.pdfContainer, !visible && styles.hidden)}
      role='document'
      aria-label='application files viewer region'
    >
      {!ViewComponent.hideScaling ? <ZoomControl setScale={setScale} /> : null}

      <ViewControl
        selectedView={selectedView}
        selectedUploadIndex={selectedUploadIndex}
        setSelectedUploadIndex={setSelectedUploadIndex}
        setSelectedView={onChangeSelectedView}
      />

      <ViewComponent
        containerRef={containerRef}
        scale={scale}
        viewOption={selectedViewOption}
        uploadIndex={selectedUploadIndex}
      />
    </div>
  )
}
