import React from 'react'

import classNames from 'classnames'
import styles from './styles.module.scss'

export default function SidebarPanel({ active, children, noPadding }) {
  return (
    <div
      className={classNames(styles.container, {
        [styles.active]: active,
        [styles.noPadding]: noPadding
      })}
    >
      {children}
    </div>
  )
}
