import React from 'react'
import { useQuery } from 'react-query'

import { ArrowLeftIcon, PageHead } from '@fullfabric/alma-mater'

import TablePageLayout from 'shared/components/TablePageLayout'

import getContract from 'apps/Billing/api/getContract'
import { CONTRACTS_TABLE_ROUTE } from 'apps/Billing/components/AppRoutes'
import { GET_CONTRACT_QUERY_KEY } from 'apps/Billing/constants/queryParts'
import getContractName from 'apps/Billing/utils/getContractName'
import EventsUnderContractTable from './EventsUnderContractTable'
import ExportButton from './ExportButton'
import SettingsButton from './SettingsButton'

export default function ContractPage({ contractId }) {
  const { data: contract, isLoading } = useQuery(
    [GET_CONTRACT_QUERY_KEY, contractId],
    () => getContract(contractId)
  )

  return (
    <TablePageLayout>
      <PageHead
        noBorder
        title={
          <span data-testid='page-title'>
            {isLoading ? 'Loading...' : getContractName(contract)}
          </span>
        }
        breadcrumbs={[
          {
            label: 'Back to billing',
            icon: ArrowLeftIcon,
            to: CONTRACTS_TABLE_ROUTE
          }
        ]}
        action={
          <>
            <ExportButton contractId={contractId} />
            <SettingsButton contractId={contractId} />
          </>
        }
      />

      <TablePageLayout.TableSection>
        <EventsUnderContractTable contractId={contractId} />
      </TablePageLayout.TableSection>
    </TablePageLayout>
  )
}
