export const CONTRACTS_TABLE_QUERY_KEY_PART = [
  'tableDataFetch',
  '/billing/api/contracts'
]

export const getApplicsUnderContractQueryKeyFilter = (contractId) => [
  'tableDataFetch',
  `/billing/api/contracts/${contractId}/events`
]

export const GET_CONTRACT_QUERY_KEY = 'getContract'
